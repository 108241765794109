import { ProtectedJsonAPI } from '.'

export const Transactions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.method('get').path(({dealSheetId}) => `deal_sheets/${dealSheetId}/transactions`),
    create: endpoint => endpoint.method('post').path(() => `transactions`),
    update: endpoint => endpoint.method('put').path(({id}) => `transactions/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `transactions/${id}`),
    events: endpoint => endpoint.method('post').path(({id}) => `transactions/${id}/events`),
  }
)