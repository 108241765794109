import APIResource    from 'services/APIResource'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import * as Actions from 'actionsets'
import * as API from 'api'
import { reducers } from 'actionsets'
import store from 'store'
import { Authorization } from 'utils'

setTimeout(sendDevObjectsToTopLevel)

let devToolsInitialised = false

export function sendDevObjectsToTopLevel(){

  if(devToolsInitialised)
    return

  devToolsInitialised = true

  const defineWindowProp = (name, prop) => {
    Object.defineProperty(window, name, {get: ()=>{
      console.warn('Direct access of top level objects. These accessors should be used from within the console only')
      return prop
    }})
  }

  defineWindowProp('Store', store);
  defineWindowProp('push', (location) => window.Store.dispatch(push(location)))
  defineWindowProp('reducers', reducers)
  defineWindowProp('APIResource', APIResource)
  defineWindowProp('API', API)
  defineWindowProp('Authorization', Authorization)

  Object.keys(Actions).forEach(exportName =>{
    let actionSet   = Actions[exportName]
    if(!actionSet || !actionSet.bindActions) return;

    let namespace = window[exportName]
    if(!namespace){
      namespace = {}
      defineWindowProp(exportName, namespace)
    }
    Object.assign(namespace, bindActionCreators(actionSet.actions, store.dispatch))
  })
}