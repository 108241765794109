import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class CommentActionSet extends ActionSet{

  static initialState = {
    comments: [],
    requests: [],
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((commentableType, commentableId, isPrivate, params) => (dispatch) => {
      return dispatch({
        type: this.INDEX,
        promise: API.Comments.index({...params, commentableType, commentableId, isPrivate})
      })
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, index: null}}
      },
      [this.INDEX_SUCCESS]: (state, { request, result: { data: comments } }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, comments }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, index: error } }
      }
    })
  }

  static create(creator, reducer, constants) {

    constants.defineRequestConstants()

    creator((commentableType, commentableId, params) => dispatch => {
      return dispatch({
        type: this.CREATE,
        promise: API.Comments.create({commentableType, commentableId, ...params})
      })
    })

    reducer({
      [this.CREATE_REQUEST]: (state, {request}) => {
        return {...state, requests: [...state.requests, request], errors: {...state.errors, create: null}}
      },
      [this.CREATE_SUCCESS]: (state, {request, result}) => {
        let {data: comment} = result
        const newRequests = state.requests.filter(r => r !== request)
        return {...state, requests: newRequests, comments: [comment, ...state.comments]}
      },
      [this.CREATE_FAILURE]: (state, {request, error}) => {
        const newRequests = state.requests.filter(r => r !== request)
        return {...state, requests: newRequests, errors: {...state.errors, create: error}}
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.UPDATE,
        promise: API.Comments.update({...params})
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, update: null} }
      },
      [this.UPDATE_SUCCESS]: (state, { request, result }) => {
        let { data: newComment } = result
        const newComments = state.comments.map(comment => comment.id === newComment.id ? newComment : comment )
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, comments: newComments }
      },
      [this.UPDATE_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: {...state.errors, update: error} }
      }
    })
  }

  static destroy(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => {
      const { id } = params

      return {
        type: this.DESTROY,
        promise: API.Comments.destroy({id})
      }
    })

    reducer({
      [this.DESTROY_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_SUCCESS]: (state, { request, result }) => {
        const { meta: { id } } = result
        const requests = state.requests.filter(r => r !== request)

        const { comments } = state
        const newComments = comments.filter(dealSheet => `${dealSheet.id}` !== `${id}`)
        return { ...state, comments: newComments, requests }
      },
      [this.DESTROY_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: { ...state.errors, destroy: error } }
      }
    })
  }
}


export default new CommentActionSet()