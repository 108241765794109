import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class AuditLogActionSet extends ActionSet{

  static initialState = {
    auditLogs: [],
    requests: [],
    errors: {
      index: null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({filter, page, order, pageSize=50} = {}) => {
      return {
        type: this.INDEX,
        promise: API.AuditLog.index({options: { page: { number: page, size: pageSize }, filter, order}}),
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, totalPages: 0, auditLogs: [], errors: {...state.errors, destroy: null, create: null, update: null }, requests: queueRequest(state, request) }
      },
      [this.INDEX_SUCCESS]: (state, { result: { data: auditLogs, meta: { totalPages }  }, request }) => {
        const requests = finishRequest(state, request)
        return {...state, auditLogs, totalPages, errors: {...state.errors, index: null }, requests }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = finishRequest(state, request)
        return {...state, errors: {...state.errors, index: error }, requests }
      }
    })
  }

}

export default new AuditLogActionSet()