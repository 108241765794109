import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'

export class ContactUsActionSet extends ActionSet{

  static initialState = {
    errors: {}
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({message, title}) => {
      return {
        type: this.CREATE,
        promise: API.ContactUs.create({message, title})
      }
    })

    reducer({
      [this.CREATE_SUCCESS]: state => {
        return {...state, errors: {create: null}}
      },
      [this.CREATE_FAILURE]: (state, {error}) => {
        return {...state, errors: { create: error } }
      }
    })
  }
}

export default new ContactUsActionSet()