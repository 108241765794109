import { ProtectedJsonAPI } from '.'

export const Users = ProtectedJsonAPI.extend(
  '/users',
  {
    index:      endpoint => endpoint,
    create:     endpoint => endpoint.method('post'),
    invite:     endpoint => endpoint.method('post').path(() => 'invite'),
    show:       endpoint => endpoint.path(({id}) => id),
    update:     endpoint => endpoint.method('put').path(({id}) => id),
    destroy:     endpoint => endpoint.method('delete').path(({id}) => id),
    changeAccountType: endpoint => endpoint.method('put').path(({id}) => `${id}/change_account_type`),
    updateTncs: endpoint => endpoint.method('put').path(({id}) => `${id}/update_tncs`),
    generate2faSecret: endpoint => endpoint.method('post').path(({id}) => `${id}/generate_2fa_secret`),
  }
)