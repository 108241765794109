import 'react-app-polyfill/ie11';
import 'polyfills'
import 'DevTools';
import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/jss-insertion-points'

const mountApp = () =>
  import('./containers').then(
    ({AppContainer}) => ReactDOM.render(
      <AppContainer />,
      document.getElementById('root')
    )
  )

const mountPastDealsCarousel = () =>
  import('./embeds').then(
    ({PastDealsCarousel}) => ReactDOM.render(
      <PastDealsCarousel
        cardProps={{
          showCountdownTimer: false,
          showProgressBar:    false,
          showRoi:            true,
          showLabels:         false,
          showTarget:         true,
          showRaised:         true,
          openLinksInNewTabs: true
        }}
        carouselProps={{
          showNavArrows: true,
          autoScrollEvery: 10000
        }}
        filter={{
          closed: true
        }}

      />,
      document.getElementById('root')
    )
  )

if(window.location.pathname.match(/past-deals-carousel\.html$/))
  mountPastDealsCarousel()
else
  mountApp()
