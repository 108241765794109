import showdown from 'showdown'

export const renderMarkdownFile = async(file) => {
  const response = await fetch(file)
  const text = await response.text()
  return renderMarkdown(text)
}

export const renderMarkdown = (text) => {
  const converter = new showdown.Converter({tables: true})
  return converter.makeHtml(text)
}