import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import jwt_decode from 'jwt-decode'
import { ResetActions } from '.'
import { TokenStore } from 'services'
import { history } from 'utils'
import { SessionStatus } from '../constants'
import { decodeJSONApiResponse } from 'utils'


export const SECOND                  = 1000
export const MINUTE                  = 60 * SECOND
export const INACTIVITY_PERIOD       = 30  * MINUTE
export const REFRESH_TOKEN_THRESHOLD = 10 * SECOND

export class TokenActionSet extends ActionSet{

  static initialState = {
    currentUser: {},
    loginState: SessionStatus.UNKNOWN,
    error: null
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  decodeToken = ({result: { data: { auth: token } }}) => {
    const { user, exp } = jwt_decode(token)
    return { currentUser: decodeJSONApiResponse(user).data, exp }
  }

  get persistGlobalReset() {
    return true
  }

  get timestamp(){
    return + new Date()
  }

  get inactive(){
    return parseInt(this.lastActivity, 10) < (this.timestamp - INACTIVITY_PERIOD)
  }

  static registerActivity(creator, reducer){
    creator(() => dispatch => this.lastActivity = this.timestamp)
    reducer({})
  }

  static startInactivityTimeout(creator, reducer){
    creator(() => (dispatch, getState) => {
      const { tokens: { exp } } = getState()
      const inactivityCheckTime = (exp * SECOND) - REFRESH_TOKEN_THRESHOLD
      clearInterval(this.onExpiryInterval)
      this.onExpiryInterval = setInterval(() => {
        if(this.timestamp > inactivityCheckTime){
          clearInterval(this.onExpiryInterval)
          dispatch( this.inactive ? this.timedOut() : this.verify())
        }
      }, SECOND)
      dispatch({
        type: this.START_INACTIVITY_TIMEOUT
      })
    })
    reducer({})
  }

  static timedOut(creator, reducer){
    creator(() => (dispatch) => {
      clearInterval(this.onExpiryInterval)
      dispatch(this.saveWindowLocation())
      history.push('/inactive', {})
      dispatch({
        type: this.TIMED_OUT
      })
    })

    reducer({
      [this.TIMED_OUT]: (state) => {
        return {...state, loginState: SessionStatus.UNKNOWN }
      }
    })
  }

  static verify(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(initial => dispatch => {
      if(initial){
        dispatch(this.saveWindowLocation())
      }
      return dispatch({
        type: this.VERIFY,
        promise: API.Tokens.refresh(TokenStore.refresh)
      }).then(() => {
        dispatch(this.startInactivityTimeout())
      })
    })

    reducer({
      [this.VERIFY_REQUEST]: (state, payload) => {
        return { ...state  }
      },
      [this.VERIFY_SUCCESS]: (state, payload) => {
        return { ...state, loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload) }
      },
      [this.VERIFY_FAILURE]: (state, payload) => {
        return { ...state, loginState: SessionStatus.UNAUTHENTICATED }
      }
    })
  }

  static create(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => dispatch => {
      return dispatch({
        type: this.CREATE,
        promise: API.Tokens.create(credentials)
      }).then(() => {
        dispatch(this.registerActivity())
        dispatch(this.startInactivityTimeout())
        dispatch(ResetActions.resetState())
      })
    })

    reducer({
      [this.CREATE_REQUEST]: (state, payload) => {
        return { ...state}
      },
      [this.CREATE_SUCCESS]: (state, payload) => {
        return { ...state, loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload), error: null }
      },
      [this.CREATE_FAILURE]: (state, { request, error }) => {
        return { ...state, loginState: SessionStatus.UNAUTHENTICATED, error }
      }
    })
  }

  static createLinkedin(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => dispatch => {
      let { authCode, redirectUri, rememberMe } = credentials
      return dispatch({
        type: this.CREATE_LINKEDIN,
        promise: API.Tokens.createLinkedIn({ authCode, redirectUri, rememberMe })
      }).then(() => {
        dispatch(this.registerActivity())
        dispatch(this.startInactivityTimeout())
        dispatch(ResetActions.resetState())
      })
    })

    reducer({
      [this.CREATE_LINKEDIN_REQUEST]: (state, payload) => {
        return { ...state, error: null}
      },
      [this.CREATE_LINKEDIN_SUCCESS]: (state, payload) => {
        return { ...state, loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload) }
      },
      [this.CREATE_LINKEDIN_FAILURE]: (state, { request, error }) => {
        return { ...state, loginState: SessionStatus.UNAUTHENTICATED, error }
      }
    })
  }

  static refresh(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((saveLocation=true) => dispatch => {
      if(saveLocation) dispatch(this.saveWindowLocation())
      return dispatch({
        type: this.REFRESH,
        promise: API.Tokens.refresh(TokenStore.refresh)
      }).then(() => {
        dispatch(this.registerActivity())
        dispatch(this.startInactivityTimeout())
      })
    })

    reducer({
      [this.REFRESH_SUCCESS]: (state) => {
        return {...state, loginState: SessionStatus.AUTHENTICATED }
      }
    })
  }

  static destroy(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(() => dispatch => {
      dispatch(this.clearSavedWindowLocation())
      return dispatch({
        type: this.DESTROY,
        promise: API.Tokens.destroy()
      })
    })

    reducer({
      [this.DESTROY_SUCCESS]: (state, payload) => {
        return { ...state, error: null, currentUser: {}, loginState: SessionStatus.UNAUTHENTICATED }
      }
    })
  }


  static forgot(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.FORGOT,
        promise: API.Tokens.forgot(credentials)
      }
    })

    reducer({
      [this.FORGOT_SUCCESS]: (state, payload) => {
        return { ...state }
      }
    })
  }

  static resendConfirmation(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.RESEND_CONFIRMATION,
        promise: API.Tokens.resendConfirmation(credentials)
      }
    })

    reducer({
      [this.RESEND_CONFIRMATION_SUCCESS]: (state, payload) => {
        return { ...state }
      }
    })
  }

  static resendInvitation(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.RESEND_INVITATION,
        promise: API.Tokens.resendInvitation(credentials)
      }
    })

    reducer({
      [this.RESEND_INVITATION_SUCCESS]: (state, payload) => {
        return { ...state }
      }
    })
  }


  static reset(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.RESET,
        promise: API.Tokens.reset(credentials)
      }
    })

    reducer({
      [this.RESET_SUCCESS]: (state, payload) => {
        return { ...state, error: null }
      },
      [this.RESET_FAILURE]: (state, { error }) => {
        return { ...state, error }
      }
    })
  }

  static acceptInvitation(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.ACCEPT_INVITATION,
        promise: API.Tokens.acceptInvitation(credentials)
      }
    })

    reducer({
      [this.ACCEPT_INVITATION_SUCCESS]: (state, payload) => {
        return { ...state, error: null }
      },
      [this.ACCEPT_INVITATION_FAILURE]: (state, { error }) => {
        return { ...state, error }
      }
    })
  }

  static confirm(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.CONFIRM,
        promise: API.Tokens.confirm(credentials)
      }
    })

    reducer({
      [this.CONFIRM_SUCCESS]: (state, payload) => {
        return { ...state, error: null }
      },
      [this.CONFIRM_FAILURE]: (state, { error }) => {
        return { ...state, error }
      }
    })
  }

  static saveWindowLocation(creator, reducer){
    creator(credentials => {
      return {
        type: this.SAVE_WINDOW_LOCATION,
        payload: window.location.pathname
      }
    })

    reducer({
      [this.SAVE_WINDOW_LOCATION]: (state, savedLocation) => {
        return { ...state, savedLocation }
      }
    })
  }

  static clearSavedWindowLocation(creator, reducer){
    creator(credentials => {
      return {
        type: this.CLEAR_SAVED_WINDOW_LOCATION
      }
    })

    reducer({
      [this.CLEAR_SAVED_WINDOW_LOCATION]: (state) => {
        return { ...state, savedLocation: null }
      }
    })
  }

  static updateInvestorState(creator, reducer) {
    creator(investorAccount => {
      return {
        type: this.UPDATE_INVESTOR_STATE,
        payload: investorAccount
      }
    })

    reducer({
      [this.UPDATE_INVESTOR_STATE]: (state, investorAccount) => {
        return {...state, currentUser: {...state.currentUser, investorStatus: investorAccount.status}}
      }
    })
  }

  static updateIssuerState(creator, reducer) {
    creator(issuerAccount => {
      return {
        type: this.UPDATE_ISSUER_STATE,
        payload: issuerAccount
      }
    })

    reducer({
      [this.UPDATE_ISSUER_STATE]: (state, issuerAccount) => {
        return {...state, currentUser: {...state.currentUser, issuerStatus: issuerAccount.status}}
      }
    })
  }

  static clearErrors(creator, reducer){
    creator(() => {
      return {
        type: this.CLEAR_ERRORS
      }
    })

    reducer({
      [this.CLEAR_ERRORS]: state => {
        return {...state, error: null }
      }
    })
  }
}

export default new TokenActionSet()