import { ProtectedJsonAPI } from '.'

export const TeamMembers = ProtectedJsonAPI.extend(
  '/deal_sheets',
  {
    index:   endpoint => endpoint.path(({dealSheetId}) => `${dealSheetId}/team_members`),
    create:  endpoint => endpoint.path(({dealSheetId}) => `${dealSheetId}/team_members`).method('post'),
    update:  endpoint => endpoint.method('put').path(({dealSheetId, id}) => `${dealSheetId}/team_members/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({dealSheetId, id}) => `${dealSheetId}/team_members/${id}`)
  }
)