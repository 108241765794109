import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'
import { queueRequest, finishRequest } from 'utils'

export class ActivityLogItemActionSet extends ActionSet{
  static initialState = {
    activityLogItems: null,
    requests: [],
    errors: {}
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator(({dealSheetId}) => {
      return {
        type: this.INDEX,
        promise: API.ActivityLogItems.index({dealSheetId})
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request), errors: {...state.error, index: null}}
      },
      [this.INDEX_SUCCESS]: (state, { request, result: {data: activityLogItems}}) => {
        return {...state, requests: finishRequest(state, request), activityLogItems}
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        return {...state, activityLogItems: [], requests: finishRequest(state, request), errors: {...state.errors, index: error}}
      }
    })
  }
}

export default new ActivityLogItemActionSet()