import { ProtectedJsonAPI } from '.'

export const Comments = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({commentableType, commentableId, isPrivate}) => `${commentableType}/${commentableId}/comments${isPrivate ? '/private' : ''}`).method('get'),
    create:  endpoint => endpoint.path(({commentableType, commentableId, isPrivate}) => `${commentableType}/${commentableId}/comments${isPrivate ? '/private' : ''}`).method('post'),
    update:  endpoint => endpoint.path(({id}) => `/comments/${id}`).method('put'),
    destroy: endpoint => endpoint.path(({id}) => `/comments/${id}`).method('delete'),
  }
)