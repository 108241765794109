import { underscore } from './StringUtils'

export function JSONApi(endpoint, {responseOnly=false, noParams=false} = {}){
  if(!responseOnly){
    endpoint.paramsHandler(encodeJSONApiParams)
  }
  if(noParams){
    endpoint.paramsHandler(() => undefined)
  }
  endpoint.resultsHandler(decodeJSONApiResponse)
          .errorHandler(decodeJSONApiError)
  return endpoint
}

export function encodeJSONApiParams(combinedAttributes){
  if(!combinedAttributes){
    return null
  }
  const { id, options, ...attributes } = combinedAttributes
  return {
    data: {
      id: attributes.id,
      attributes
    },
    ...options
  }
}

export function decodeJSONApiResponse(results, options = {}) {
  let { method } = options
  let { meta } = results
  if(method === 'delete') {
    // see http://jsonapi.org/format/#crud-deleting
    return { meta }
  }

  let { data: result, included } = results

  if (result === null || result === undefined)
    throw new Error('result should not be null or undefined')

  if (Array.isArray(result)) {
    return {data: result.map(r => decodeJSONApiResponse({data: r, included}).data), meta}
  } else {
    const { id, type, attributes, relationships } = result
    const decoded = {
      data: {
        id, type, ...attributes
      }, meta
    }
    if(relationships){
      fillRelationships(decoded, relationships, buildIncludedMap(included))
    }
    return decoded
  }
}

function buildIncludedMap(included){
  const includedMap = {}
  included = included || []
  included.forEach(included => {
    includedMap[`${included.id}.${included.type}`] = {id: included.id, type: included.type, ...included.attributes }
  })
  included.forEach(({id, type, relationships}) => {
    if(relationships && Object.entries(relationships).length){
      const object = includedMap[`${id}.${type}`]
      Object.entries(relationships).forEach(([relationship, {data}]) => {
        const extractObject = ({id, type}) => includedMap[`${id}.${type}`]
        if(data) {
          object[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
        } else {
          object[relationship] = null
        }
      })
    }
  })
  return includedMap
}

function fillRelationships(decoded, relationships, included){
  Object.entries(relationships).forEach(([relationship, {data}]) => {
    const extractObject = ({id, type, relationships}) => included[`${id}.${type}`]
    if(data) {
      decoded.data[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
    } else {
      decoded.data[relationship] = null
    }
  })
  return decoded
}

export function decodeJSONApiError(error){
  if(error && error.body && error.body.error){
    return error.body.error
  }
  return error
}

export function underscoreParameters(object) {
  if (object === null || typeof object !== 'object')
    return object

  if (Array.isArray(object))
    return object.map(value => underscoreParameters(value))

  const newObject = {}
  Object.keys(object).forEach(key => {
    newObject[underscore(key)] = underscoreParameters(object[key])
  })
  return newObject
}
