import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { TokenActions } from './'

export class IssuerActionSet extends ActionSet{

  static initialState = {
    issuer: {},
    requests: [],
    searchResults: [],
    errors: {
      create:  null,
      update:  null,
      show:    null,
      createEvent: null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return async(dispatch) => {
        const issuerAccount = await dispatch({
          type: this.UPDATE,
          promise: API.Issuers.update(params)
        })
        dispatch(TokenActions.updateIssuerState(issuerAccount.data))
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, request) => {
        return {...state, requests: [...state.requests, request] }
      },
      [this.UPDATE_SUCCESS]: (state, { request, result: { data: issuer } }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, issuer, requests, errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, update: error } }
      }
    })
  }

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return async(dispatch) => {
        const issuerAccount = await dispatch({
          type: this.CREATE,
          promise: API.Issuers.update(params)
        })
        dispatch(TokenActions.updateIssuerState(issuerAccount.data))
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, request) => {
        return {...state, requests: [...state.requests, request] }
      },
      [this.CREATE_SUCCESS]: (state, { request }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, create: null } }
      },
      [this.CREATE_FAILURE]: (state, { request, error}) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, create: error } }
      }
    })
  }

  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.Issuers.show(params)
      }
    })

    reducer({
      [this.SHOW_REQUEST]: state => {
        return {...state, issuer: {}, errors: {...state.errors, create: null, update: null, createEvent: null } }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: issuer } }) => {
        return {...state, issuer, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { request, error }) => {
        return {...state, errors: {...state.errors, show: error } }
      }
    })
  }

  static createEvent(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((issuerId, eventParams) => {
      return async (dispatch) => {
        const params = {id: issuerId, ...eventParams}
        const issuerAccount = await dispatch({
          type: this.CREATE_EVENT,
          promise: API.Issuers.events(params)
        })
        dispatch(TokenActions.updateIssuerState(issuerAccount.data))
      }
    })

    reducer({
      [this.CREATE_EVENT_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, createEvent: null} }
      },
      [this.CREATE_EVENT_SUCCESS]: (state, { request, result }) => {
        const { data: issuer } = result
        const requests = state.requests.filter(r => r !== request)

        const newissuer = (state.issuer.id === issuer.id) ? issuer : state.issuer
        return { ...state, issuer: newissuer, requests }
      },
      [this.CREATE_EVENT_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: {...state.errors, createEvent: error} }
      }
    })
  }

  static search(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(searchString => async(dispatch, getState) => {
      await dispatch({
        type: this.SEARCH,
        promise: API.Issuers.search({options: {filter: searchString}})
      })
      return getState().issuers.searchResults
    })

    reducer({
      [this.SEARCH_REQUEST]: (state, { request }) => {
        return { ...state, searchResults: [], requests: [...state.requests, request], errors: {...state.errors, search: null} }
      },
      [this.SEARCH_SUCCESS]: (state, { request, result: { data: searchResults} }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, searchResults, requests }
      },
      [this.SEARCH_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, searchResults: [], requests, errors: {...state.errors, search: error} }
      }
    })
  }
}


export default new IssuerActionSet()