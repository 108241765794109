import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { future } from 'utils'
import { queueRequest, finishRequest } from 'utils'

export class UserSelectionActionSet extends ActionSet{

  static initialState = {
    currentPage:        [],
    matchingIds:        [],
    currentSelection:   [],
    userSelectHandlers: {},
    requests:           [],
    title:              '',
    actionName:         '',
    cancelActionName:   '',
    totalPages:         0,
    open:             false
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((page, filter={}) => (dispatch, getState) => {
      page = page !== undefined ? page : getState().userSelect.page
      return dispatch({
        type: this.INDEX,
        promise: API.Users.index({options: {include_ids: true, page: { number: page, size: 15 }, filter }})
      })
    })

    reducer({
      [this.INDEX_REQUEST]: (state, {request}) => {
        return {...state, currentPage: [], requests: queueRequest(state, request) }
      },
      [this.INDEX_SUCCESS]: (state, { result: { data: currentPage, meta: { totalPages, ids: matchingIds } }, request }) => {
        return {...state, currentPage, totalPages, requests: finishRequest(state, request), matchingIds }
      },
      [this.INDEX_FAILURE]: (state, { error, request }) => {
        return {...state, error, requests: finishRequest(state, request) }
      }
    })
  }

  static makeSelection(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((options={}) => async dispatch => {
      const { promise: selectAction, onResolve: onSelect, onReject: onCancel } = future()
      dispatch(this.open(options))
      try{
        const users = await dispatch({
          type: this.MAKE_SELECTION,
          payload: { onSelect, onCancel },
          promise: selectAction
        })
        dispatch(this.close())
        return users
      }
      catch(err){
        dispatch(this.close())
        return Promise.reject(err)
      }
    })

    reducer({
      [this.MAKE_SELECTION_REQUEST]: (state, { requestPayload: userSelectHandlers }) => {
        return {...state, userSelectHandlers }
      }
    })
  }

  static open(creator, reducer){
    creator((options={}) => {
      return {
        type: this.OPEN,
        payload: options
      }
    })

    reducer({
      [this.OPEN]: (state, {title='', cancelActionName='', actionName=''}={}) => {
        return {...state, open: true, title, cancelActionName, actionName}
      }
    })
  }

  static close(creator, reducer){
    creator(() => {
      return {
        type: this.CLOSE
      }
    })

    reducer({
      [this.CLOSE]: state => {
        return {...state, open: false}
      }
    })
  }

  static select(creator, reducer){
    creator((ids) => (dispatch, getState) => {
      getState().userSelect.userSelectHandlers.onSelect(ids)
      return dispatch({
        type: this.SELECT
      })
    })

    reducer({})
  }

  static cancel(creator, reducer){
    creator((ids) => (dispatch, getState) => {
      getState().userSelect.userSelectHandlers.onCancel()
      return dispatch({
        type: this.CANCEL
      })
    })

    reducer({})
  }
}

export default new UserSelectionActionSet()