import { TokenStore } from 'services'

class AuthorizationProxy{

  set store(value){
    this._store = value
  }

  get store(){
    this._store = this._store || require('store').default
    return this._store
  }

  get user(){
    return this.store.getState().tokens.currentUser
  }

  get admin(){
    return (this.user.accessLevel === 'admin')
  }

  get investor(){
    return (this.investorStatus === 'approved' && this.investorTncsAgreed)
  }

  get issuer(){
    return (this.issuerStatus === 'approved' && this.issuerTncsAgreed)
  }

  get registeredInvestor(){
    return (this.investorStatus in registeredStatuses && this.investorTncsAgreed)
  }

  get draftInvestor(){
    return (this.investorStatus === 'draft' && this.investorTncsAgreed)
  }

  get registeredIssuer(){
    return (this.issuerStatus in registeredStatuses && this.issuerTncsAgreed)
  }

  get draftIssuer(){
    return (this.issuerStatus === 'draft' && this.issuerTncsAgreed)
  }

  get investorStatus(){
    return this.user.investorStatus
  }

  get issuerStatus(){
    return this.user.issuerStatus
  }

  get promptKyc(){
    return !!(this.user && this.user.promptKyc)
  }

  get prompt2fa(){
    return !!(this.user && (this.user.otpRequiredForLogin === null))
  }

  get approved(){
    return this.admin || this.investor || this.issuer
  }

  get registered() {
    return this.admin || this.registeredInvestor || this.registeredIssuer
  }

  get notApplied(){
    return (
      (!this.admin) &&
      (this.investorStatus === 'not_applied' || this.investorStatus === 'draft') &&
      (this.issuerStatus   === 'not_applied' || this.issuerStatus === 'draft')
    )
  }

  get investorTncsAgreed() {
    return !!(this.user.investorTncsAgreement && this.user.investorTncsAgreement !== 'expired')
  }

  get issuerTncsAgreed() {
    return !!(this.user.issuerTncsAgreement && this.user.issuerTncsAgreement !== 'expired')
  }

  get tncsAgreed() {
    return (!(this.investorStatus in registeredStatuses) || this.investorTncsAgreed) && (!(this.issuerStatus in registeredStatuses) || this.issuerTncsAgreed)
  }

  get tncsExpired() {
    return (this.user.investorTncsAgreement === 'expired' || this.user.issuerTncsAgreement === 'expired' )
  }
}

export const registeredStatuses = {approved: true, pending_review: true, registered: true}

export const Authorization = new AuthorizationProxy()
export const authorizedLink = (urlString) => {
  const tryUrl = (urlString) => {
    try {
      return new URL(urlString)
    } catch(e) {
      return null
    }
  }

  const apiHost = process.env.REACT_APP_API_HOST
  const paddedUrl = `${apiHost}${urlString}`
  let url = tryUrl(urlString) || tryUrl(paddedUrl)
  if(!url) {
    throw new Error(`Failed to parse url. I have tried both ${urlString} and ${paddedUrl}`)
  }

  url.searchParams.append('Authorization', TokenStore.auth)
  return url.toString()
}