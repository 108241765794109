import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class ThirdPartyActionSet extends ActionSet{

  static initialState = {
    thirdParties: [],
    thirdParty:   { commissions: [] },
    requests:     [],
    totalPages:   0,
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null,
      show:    null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((page, pageSize=10) => {
      return {
        type: this.INDEX,
        promise: API.ThirdParties.index({options: { page: { number: page, size: pageSize }}}),
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, totalPages: 0, thirdParties: [], errors: {...state.errors, destroy: null, create: null, update: null }, requests: queueRequest(state, request) }
      },
      [this.INDEX_SUCCESS]: (state, { result: { data: thirdParties, meta: { totalPages }  }, request }) => {
        const requests = finishRequest(state, request)
        return {...state, thirdParties, totalPages, errors: {...state.errors, index: null }, requests }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = finishRequest(state, request)
        return {...state, errors: {...state.errors, index: error }, requests }
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.UPDATE,
        promise: API.ThirdParties.update(params)
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.UPDATE_SUCCESS]: (state, { request }) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, update: error } }
      }
    })
  }

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.CREATE,
        promise: API.ThirdParties.create(params)
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.CREATE_SUCCESS]: (state, { request }) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, create: null } }
      },
      [this.CREATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, create: error } }
      }
    })
  }

  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.ThirdParties.show(params)
      }
    })

    reducer({
      [this.SHOW_REQUEST]: state => {
        return {...state, thirdParty: { commissions: [] }, errors: {...state.errors, create: null, update: null } }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: thirdParty } }) => {
        return {...state, thirdParty, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { request, error }) => {
        return {...state, errors: {...state.errors, show: error } }
      }
    })
  }

  static destroy(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.DESTROY,
        promise: API.ThirdParties.destroy(params)
      }
    })

    reducer({
      [this.DESTROY_REQUEST]: state => {
        return {...state, thirdParty: { commissions: [] }, errors: {...state.errors, create: null, update: null } }
      },
      [this.DESTROY_SUCCESS]: (state, { result }) => {
        return {...state, errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_FAILURE]: (state, { request, error }) => {
        return {...state, errors: {...state.errors, destroy: error } }
      }
    })
  }

   static search(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(searchString => async(dispatch, getState) => {
      await dispatch({
        type: this.SEARCH,
        promise: API.ThirdParties.search({options: {filter: searchString}})
      })
      return getState().thirdParties.searchResults
    })

    reducer({
      [this.SEARCH_REQUEST]: (state, { request }) => {
        return { ...state, searchResults: [], requests: queueRequest(state, request) , errors: {...state.errors, search: null} }
      },
      [this.SEARCH_SUCCESS]: (state, { request, result: { data: searchResults} }) => {
        const requests = finishRequest(state, request)
        return { ...state, searchResults, requests }
      },
      [this.SEARCH_FAILURE]: (state, { request, error }) => {
        const requests = finishRequest(state, request)
        return { ...state, searchResults: [], requests, errors: {...state.errors, search: error} }
      }
    })
  }
}

export default new ThirdPartyActionSet()