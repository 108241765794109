import { ProtectedJsonAPI } from '.'

export const DealSheets = ProtectedJsonAPI.extend(
  '/deal_sheets',
  {
    index:                     endpoint => endpoint,
    create:                    endpoint => endpoint.method('post'),
    update:                    endpoint => endpoint.method('put').path(({id}) => id),
    updateFiles:               endpoint => endpoint.useFormData(true).method('put').path(({id}) => id),
    termsAndConditionsPreview: endpoint => endpoint.useFormData(true).method('post').path(() => 'terms_and_conditions_preview'),
    show:                      endpoint => endpoint.path(({id}) => id),
    destroy:                   endpoint => endpoint.path(({id}) => id).method('delete'),
    updateTeamMembers:         endpoint => endpoint.method('put').path(({id}) => `${id}/team_members`),
    indexTeamMembers:          endpoint => endpoint.path(({id}) => `${id}/team_members`),
    updatePartners:            endpoint => endpoint.useFormData(true).method('put').path(({id}) => `${id}/partners`),
    indexPartners:             endpoint => endpoint.path(({id}) => `${id}/partners`),
    updateSocialMediaLinks:    endpoint => endpoint.useFormData(true).method('put').path(({id}) => `${id}/social_media_links`),
    events:                    endpoint => endpoint.method('post').path(({id}) => `${id}/events`),
    fetchDetails:              endpoint => endpoint.method('get').path(({id}) => `${id}/details`),
    permittedViewers:          endpoint => endpoint.method('get').path(({id}) => `${id}/viewers`),
    restrictedViewers:         endpoint => endpoint.method('get').path(({id}) => `${id}/viewers`).paramsHandler(params => {return {...params, restricted: true}}),
    permitViewers:             endpoint => endpoint.method('post').path(({id}) => `${id}/viewers/bulk`),
    restrictViewers:           endpoint => endpoint.method('post').path(({id}) => `${id}/viewers/bulk`).paramsHandler(params => {return {...params, restrict: true}}),
    removeViewer:              endpoint => endpoint.method('delete').path(({id, viewerId}) => `${id}/viewers/${viewerId}`),
    setFeaturedListings:       endpoint => endpoint.method('put').path(() => `set_featured_listings`),
  }
)