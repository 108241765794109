import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class AllocationActionSet extends ActionSet{

  static initialState = {
    nomination:  null,
    requests:    [],
    newInvestorIds:     [],
    editAllocationIds:  [],
    selection:   {},
    selectedTab: 'active',
    allocations: [],
    errors:      {},
    orderBy:     'investor_accounts.account_name',
    orderDir:   'asc',
    bulkParams:  null,
    totalPages:  0,
    totals:      {},
    termsAndConditions: null
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static show(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({id}) => {
      return {
        type: this.SHOW,
        promise: API.Allocations.show({id})
      }
    })

    reducer({
      [this.SHOW_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: allocation }, request }) => {
        return {...state, requests: finishRequest(state, request), allocation, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), allocation: null, errors: {...state.errors, show: error } }
      }
    })
  }

  static index(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({dealSheetId, page, size=10, order, filter={}}={}) => (dispatch) =>{
      const options = { page: { number: page, size }, filter, order}
      const promise = (dealSheetId) ? API.Allocations.index({dealSheetId, options}) : API.Allocations.all({options})

      return dispatch({
        type: this.INDEX,
        promise
      })
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.INDEX_SUCCESS]: (state, { result: { data: allocations, meta: { totalPages, page, totals }}, request }) => {
        return {...state, requests: finishRequest(state, request), allocations, totals, errors: {...state.errors, index: null }, page, totalPages }
      },
      [this.INDEX_FAILURE]: (state, { error, request}) => {
        return {...state, allocations: [], requests: finishRequest(state, request), errors: {...state.errors, index: error } }
      }
    })
  }

  static setNewInvestorIds(creator, reducer){
    creator(({ids, params}) => {
      return {
        type: this.SET_NEW_INVESTOR_IDS,
        payload: {ids, params}
      }
    })

    reducer({
      [this.SET_NEW_INVESTOR_IDS]: (state, {ids: newInvestorIds, params: bulkParams}) => {
        return {...state, newInvestorIds, bulkParams }
      }
    })
  }

  static setEditAllocationIds(creator, reducer){
    creator(({ids, params}) => {
      return {
        type: this.SET_EDIT_ALLOCATION_IDS,
        payload: {ids, params}
      }
    })

    reducer({
      [this.SET_EDIT_ALLOCATION_IDS]: (state, {ids: editAllocationIds, params: bulkParams}) => {
        return {...state, editAllocationIds, bulkParams }
      }
    })
  }

  static bulkInsert(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => dispatch => {
      return dispatch({
        type: this.BULK_INSERT,
        promise: API.Allocations.bulkInsert(params)
      }).then(
        dispatch(this.setSelection({}))
      )
    })

    reducer({
      [this.BULK_INSERT_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.BULK_INSERT_SUCCESS]: (state, { result: { data: allocations }, request }) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, bulkInsert: null } }
      },
      [this.BULK_INSERT_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, bulkInsert: error } }
      }
    })
  }

  static bulkUpdate(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => dispatch => {
      return dispatch({
        type: this.BULK_UPDATE,
        promise: API.Allocations.bulkUpdate(params)
      }).then(
        dispatch(this.setSelection({}))
      )
    })

    reducer({
      [this.BULK_UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.BULK_UPDATE_SUCCESS]: (state, { result: { data: allocations }, request }) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, bulkUpdate: null } }
      },
      [this.BULK_UPDATE_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, bulkUpdate: error } }
      }
    })
  }

  static setSelection(creator, reducer){
    creator(selection => {
      return {
        type:this.SET_SELECTION,
        payload: selection
      }
    })

    reducer({
      [this.SET_SELECTION]: (state, selection) => {
        return {...state, selection}
      }
    })
  }


  static setSelectedTab(creator, reducer){
    creator(selectedTab => {
      return {
        type:this.SET_SELECTED_TAB,
        payload: selectedTab
      }
    })

    reducer({
      [this.SET_SELECTED_TAB]: (state, selectedTab) => {
        return {...state, selectedTab}
      }
    })
  }


  static setOrderParams(creator, reducer){
    creator(({orderBy, orderDir}) => {
      return {
        type:this.SET_ORDER_PARAMS,
        payload: {orderBy, orderDir}
      }
    })

    reducer({
      [this.SET_ORDER_PARAMS]: (state, {orderBy, orderDir}) => {
        return {...state, orderBy, orderDir}
      }
    })
  }

  static update(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((allocation) => {
      return {
        type: this.UPDATE,
        promise: API.Allocations.update(allocation)
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.UPDATE_SUCCESS]: (state, { result: {data: allocation}, request },) => {
        let allocations = state.allocations.map(a => a.id === allocation.id ? allocation : a )
        return {...state, requests: finishRequest(state, request), allocations, errors: {...state.errors, update_tokens_sent: null } }
      },
      [this.UPDATE_FAILURE]: (state, { error, request }) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, update_tokens_sent: error } }
      }
    })
  }

  static destroy(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({id}) => {
      return {
        type: this.DESTROY,
        promise: API.Allocations.destroy({id})
      }
    })

    reducer({
      [this.DESTROY_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.DESTROY_SUCCESS]: (state, { result: { meta: {id: destroyedId} }, request }) => {
        let allocations = state.allocations.filter(({id}) => `${id}` !== `${destroyedId}`)
        return {...state, requests: finishRequest(state, request), allocations, allocation: null, errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, destroy: error } }
      }
    })
  }

  static invest(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((params) => {
      return {
        type: this.INVEST,
        promise: API.Allocations.invest(params)
      }
    })

    reducer({
      [this.INVEST_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.INVEST_SUCCESS]: (state, { result: { data: allocation }, request }) => {
        return {...state, requests: finishRequest(state, request), allocations: state.allocations.map(a => a.id === allocation.id ? allocation : a), errors: {...state.errors, invest: null } }
      },
      [this.INVEST_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, invest: error } }
      }
    })
  }

  static clearErrors(creator, reducer){
    creator(() => {
      return {
        type: this.CLEAR_ERRORS
      }
    })

    reducer({
      [this.CLEAR_ERRORS]: state => {
        return {...state, errors: {} }
      }
    })
  }

  static loadTermsAndConditions(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((params) => {
      return {
        type: this.LOAD_TERMS_AND_CONDITIONS,
        promise: API.Allocations.terms_and_conditions(params)
      }
    })

    reducer({
      [this.LOAD_TERMS_AND_CONDITIONS_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.LOAD_TERMS_AND_CONDITIONS_SUCCESS]: (state, { request, result }) => {
        const { data: { content } } = result
        return {...state, requests: finishRequest(state, request), termsAndConditions: content, errors: {...state.errors, loadTermsAndConditions: null } }
      },
      [this.LOAD_TERMS_AND_CONDITIONS_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, loadTermsAndConditions: error } }
      }
    })
  }
}

export default new AllocationActionSet()