import ActionSet from './ActionSet'
import { NotificationActions } from '.'
export class ResetActionSet extends ActionSet{

  static initialState = {}

  static resetState(creator, reducer){
    creator(() => dispatch => {
      dispatch(NotificationActions.resetState())
      dispatch({type: this.RESET_STATE})
    })

    reducer({})
  }
}

export default new ResetActionSet()