import { ProtectedJsonAPI } from '.'

export const Nominations = ProtectedJsonAPI.extend(
  '/deal_sheets',
  {
    index:   endpoint => endpoint.path(({dealSheetId}) => `${dealSheetId}/nominations`),
    create:  endpoint => endpoint.maxConcurrent(1).path(({dealSheetId}) => `${dealSheetId}/nominations`).method('post'),
    show:    endpoint => endpoint.path(({dealSheetId}) => `${dealSheetId}/nomination`).method('get'),
    destroy: endpoint => endpoint.base('/').path(({id}) => `nominations/${id}`).method('delete')
  }
)