import { ProtectedJsonAPI } from '.'

export const Tokens = ProtectedJsonAPI.extend(
  '/tokens',
  {
    create:             endpoint => endpoint.method('post'),
    createLinkedIn:     endpoint => endpoint.method('post').path('linkedin'),
    refresh:            endpoint => endpoint.method('put').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth} : {}) }}),
    destroy:            endpoint => endpoint.method('delete'),
    forgot:             endpoint => endpoint.method('post').path('forgot'),
    resendConfirmation: endpoint => endpoint.method('post').path('resend_confirmation'),
    resendInvitation:   endpoint => endpoint.method('post').path('resend_invitation'),
    reset:              endpoint => endpoint.method('post').path('reset'),
    confirm:            endpoint => endpoint.method('post').path('confirm'),
    acceptInvitation:   endpoint => endpoint.method('post').path('accept_invitation')
  }
)