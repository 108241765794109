import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'

export class CurrencyActionSet extends ActionSet {
  static initialState = {
    currencies: [],
    requests: [],
    errors: {
      index:   null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(() => {
      return {
        type: this.INDEX,
        promise: API.Currencies.index()
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, index: null } }
      },
      [this.INDEX_SUCCESS]: (state, { request, result }) => {
        let { data: currencies } = result
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, currencies }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: { ...state.errors, index: error } }
      }
    })
  }
}

export default new CurrencyActionSet()