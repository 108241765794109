import moment from 'moment'

import { DateTimeUtils } from '.'

/**
 * Calculate the values and labels for the countdown-timer
 */
export function countdownStatus(deal){
  let status = {
    prefix: "",
    timeRemaining: "Coming Soon",
    info: "",
    code: 'commingSoon'
  }
  let { startDate, endDate } = deal
  startDate = moment(startDate)
  endDate   = moment(endDate)
  const now       = moment()
  const endDiff   = moment.duration(endDate.diff(now))
  const startDiff = moment.duration(startDate.diff(now))

  if(startDate > now){
    status.prefix        = 'Starts in'
    status.timeRemaining = DateTimeUtils.displayTimeDiff(startDiff)
    status.info          = `Presale starts ${startDate.format('MMM Do, YYYY, h:mm:ss Z')}`
    status.code          = 'startsIn'
  }
  else if(now < endDate){
    status.prefix        = 'Ends in'
    status.timeRemaining = DateTimeUtils.displayTimeDiff(endDiff)
    status.info          = `Presale ends ${endDate.format('MMM Do, YYYY, h:mm:ss Z')}`
    status.code          = 'endsIn'
  }
  else if (now > endDate){
    status.timeRemaining        = 'Closed'
    status.info          = `Presale ended ${endDate.format('MMM Do, YYYY, h:mm:ss Z')}`
    status.code          = 'closed'
  }
  return status
}