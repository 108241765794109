import ActionSet from './ActionSet'

export class GlobalLoaderActionSet extends ActionSet{

  static initialState = {
    loadCounter: 0,
    loading: false
  }

  static startLoad(creator, reducer){
    creator(() => {
      return {
        type: this.START_LOAD
      }
    })

    reducer({
      [this.START_LOAD]: state => {
        const loadCounter = Math.max(state.loadCounter + 1, 1)
        const loading = !!loadCounter
        return {...state, loadCounter, loading}
      }
    })
  }

  static stopLoad(creator, reducer){
    creator(() => {
      return {
        type: this.STOP_LOAD
      }
    })

    reducer({
      [this.STOP_LOAD]: state => {
        const loadCounter = Math.max(state.loadCounter - 1, 0)
        const loading = !!loadCounter
        return {...state, loadCounter, loading}
      }
    })
  }

  static clearLoad(creator, reducer){
    creator(() => {
      return {
        type: this.CLEAR_LOAD
      }
    })

    reducer({
      [this.CLEAR_LOAD]: (state, {message, status}) => {
        return {...GlobalLoaderActionSet.initialState}
      }
    })
  }

}

export default new GlobalLoaderActionSet()