import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class NotificationActionSet extends ActionSet{

  static initialState = {
    count: 0,
    notifications: [],
    requests: [],
    totalPages: 0,
    errors: {
      show: null,
      unreadCount: null,
      markAsRead: null,
      markAsUnread: null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static show(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((page = 0) => {
      return {
        type: this.SHOW,
        promise: API.Notifications.show({options: {page: { number: page, size: 10 }}})
      }
    })

    reducer({
      [this.SHOW_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: notifications, meta: { totalPages } }, request }) => {
        return {...state, requests: finishRequest(state, request), totalPages, notifications, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), notifications: [], errors: {...state.errors, show: error } }
      }
    })
  }

  static create(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.CREATE,
        promise: API.Notifications.create(params)
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request), errors: {...state.errors, create: null }  }
      },
      [this.CREATE_SUCCESS]: (state, { result, request }) => {
        return {...state, requests: finishRequest(state, request)}
      },
      [this.CREATE_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, create: error } }
      }
    })
  }

  static unreadCount(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(() =>{
      return {
        type: this.UNREAD_COUNT,
        promise: API.Notifications.show({options:
          {
            page: { size: 0 },
            filter: { read: false }
          }
        })
      }
    })

    reducer({
      [this.UNREAD_COUNT_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.UNREAD_COUNT_SUCCESS]: (state, { result: { meta: { count } }, request }) => {
        return {...state, requests: finishRequest(state, request), count, errors: {...state.errors, unreadCount: null } }
      },
      [this.UNREAD_COUNT_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, unreadCount: error } }
      }
    })
  }

  static markAsUnread(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(ids => {
      return {
        type: this.MARK_AS_UNREAD,
        promise: API.Notifications.bulkUpdate({ids, read: false})
      }
    })

    reducer({
      [this.MARK_AS_UNREAD_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request), errors: {...state.errors, markAsUnread: null } }
      },
      [this.MARK_AS_UNREAD_SUCCESS]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request) }
      },
      [this.MARK_AS_UNREAD_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, markAsUnread: error } }
      }
    })
  }

  static markAsRead(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(ids => {
      return {
        type: this.MARK_AS_READ,
        promise: API.Notifications.bulkUpdate({ids, read: true})
      }
    })

    reducer({
      [this.MARK_AS_READ_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request), errors: {...state.errors, markAsRead: null } }
      },
      [this.MARK_AS_READ_SUCCESS]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request) }
      },
      [this.MARK_AS_READ_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, markAsRead: error } }
      }
    })
  }

}

export default new NotificationActionSet()