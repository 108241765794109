import { ProtectedJsonAPI } from '.'

export const Allocations = ProtectedJsonAPI.extend(
  '',
  {
    show:       endpoint => endpoint.path(({id}) => `allocations/${id}`),
    destroy:    endpoint => endpoint.path(({id}) => `allocations/${id}`).method('delete'),
    update:     endpoint => endpoint.path(({id}) => `allocations/${id}`).method('put'),
    invest:     endpoint => endpoint.method('put').path(({id}) => `allocations/${id}/invest`),
    bulkInsert: endpoint => endpoint.path(({dealSheetId}) => `deal_sheets/${dealSheetId}/allocations/bulk_insert`).method('post'),
    bulkUpdate: endpoint => endpoint.path(({dealSheetId}) => `deal_sheets/${dealSheetId}/allocations/bulk_update`).method('post'),
    index:      endpoint => endpoint.path(({dealSheetId}) => `deal_sheets/${dealSheetId}/allocations`),
    all:        endpoint => endpoint.method('get').path(() => 'allocations'),
    terms_and_conditions: endpoint => endpoint.path(({id}) => `allocations/${id}/terms_and_conditions`)
  }
)