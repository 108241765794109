import ActionSet from './ActionSet'
import CurrencyActions from './CurrencyActionSet'
import DealSheetActions from './DealSheetActionSet'
import DownloadActions from './DownloadActionSet'
import GlobalLoaderActions from './GlobalLoaderActionSet'
import InvestorActions from './InvestorActionSet'
import IssuerActions from './IssuerActionSet'
import NominationActions from './NominationActionSet'
import NotificationActions from './NotificationActionSet'
import RatesActions from './RatesActionSet'
import ResetActions from './ResetActionSet'
import SnackbarActions from './SnackbarActionSet'
import ThirdPartyActions from './ThirdPartyActionSet'
import TokenActions from './TokenActionSet'
import UserActions from './UserActionSet'
import UserSelectionActions from './UserSelectionActionSet'
import CommentActions from './CommentActionSet'
import AllocationActions from './AllocationActionSet'
import TransactionActions from './TransactionActionSet'
import ActivityLogItemActions from './ActivityLogItemActionSet'
import TeamMemberActions from './TeamMemberActionSet'
import PartnersActions from './PartnersActionSet'
import SocialMediaLinksActions from './SocialMediaLinksActionSet'
import CountryActions from './CountryActionSet'
import CountryGroupActions from './CountryGroupActionSet'
import ContactUsActions from './ContactUsActionSet'
import DocumentActions from './DocumentActionSet'
import SocialMediaWebsitesActions from './SocialMediaWebsitesActionSet'
import ServiceActions from './ServiceActionSet'
import ThemeActions from './ThemeActionSet'
import OrganisationActions from './OrganisationActionSet'
import PersonKycActions from './PersonKycActionSet'
import AuditLogActions from './AuditLogActionSet'

export const reducers = {
  currencies:    CurrencyActions.reducers,
  dealSheets:    DealSheetActions.reducers,
  globalLoader:  GlobalLoaderActions.reducers,
  investors:     InvestorActions.reducers,
  issuers:       IssuerActions.reducers,
  nominations:   NominationActions.reducers,
  notifications: NotificationActions.reducers,
  rates:         RatesActions.reducers,
  snackbar:      SnackbarActions.reducers,
  thirdParties:  ThirdPartyActions.reducers,
  tokens:        TokenActions.reducers,
  users:         UserActions.reducers,
  userSelect:    UserSelectionActions.reducers,
  comments:      CommentActions.reducers,
  allocations:   AllocationActions.reducers,
  transactions:  TransactionActions.reducers,
  activityLogItems: ActivityLogItemActions.reducers,
  teamMembers:   TeamMemberActions.reducers,
  partners:      PartnersActions.reducers,
  socialMediaLinks: SocialMediaLinksActions.reducers,
  countries:     CountryActions.reducers,
  countryGroups: CountryGroupActions.reducers,
  contactUs:     ContactUsActions.reducers,
  downloads:     DownloadActions.reducers,
  documents:     DocumentActions.reducers,
  socialMediaWebsites: SocialMediaWebsitesActions.reducers,
  services:      ServiceActions.reducers,
  themes:        ThemeActions.reducers,
  organisations: OrganisationActions.reducers,
  personKycs:    PersonKycActions.reducers,
  auditLogs:      AuditLogActions.reducers,
}

export {
  ActionSet,
  CurrencyActions,
  CommentActions,
  DealSheetActions,
  DownloadActions,
  GlobalLoaderActions,
  InvestorActions,
  IssuerActions,
  NotificationActions,
  NominationActions,
  RatesActions,
  ResetActions,
  SnackbarActions,
  ThirdPartyActions,
  TokenActions,
  UserActions,
  UserSelectionActions,
  AllocationActions,
  TransactionActions,
  ActivityLogItemActions,
  TeamMemberActions,
  PartnersActions,
  SocialMediaLinksActions,
  CountryActions,
  CountryGroupActions,
  ContactUsActions,
  DocumentActions,
  SocialMediaWebsitesActions,
  ServiceActions,
  ThemeActions,
  OrganisationActions,
  PersonKycActions,
  AuditLogActions,
}
