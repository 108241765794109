export function compact(collection){
  return collection.filter(x => x)
}

export function flatten(collection){
  return collection.reduce(function(prev, inner) {
    inner = Array.isArray(inner) ? inner : [inner]
    const  multiLevel = (inner).some(Array.isArray);
    return prev.concat(multiLevel ? flatten(inner) : inner);
  },[]);
}

export function unique(collection){
  return collection.filter((x, i, a) => a.indexOf(x) === i)
}

export function collectionPresent(collection){
  return collection != null && !!collection.length
}

export function any(collection){
  return collection != null && collectionPresent(Object.values(collection).filter(x => x))
}

export function groupCollectionBy(collection, ...by){
  const grouped = {}
  collection.forEach(item => {
    const key = by.map(byValue => `${item[byValue]}`).join('::')
    grouped[key] = grouped[key] || []
    grouped[key].push(item)
  })
  return grouped
}

export function deepMerge(obj1, obj2) {
  if (!isObject(obj1) || !isObject(obj2))
    return coalesce(obj2, obj1)

  const newObj = {...obj1}
  Object.keys(obj2).forEach(key => {
    newObj[key] = deepMerge(obj1[key], obj2[key])
  });

  return newObj
}

export function deepGet(name, object) {
  return name.split(/(?:\[(?=\d)|(?!=\d)\]\.?|\.)/).filter(x => x !== "").reduce((memo, part) => memo ? memo[part] : null, object)
}

export function deepSet(value, name, object) {
  const attributeChain = name.split(/\[(?=\d)|(?!=\d)\]\.?|\./).filter(x => x !== "")
  const updatedContext = {...object}
  const valueObject = attributeChain.slice(0, -1).reduce((result, att) => (result[att] = Array.isArray(result[att]) ? [...result[att]] : {...result[att]}), updatedContext)
  valueObject[attributeChain[attributeChain.length-1]] = value
  return updatedContext
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function coalesce(...items) {
  for (let item of items) {
    if (item !== null && item !== undefined)
      return item
  }
  return items[items.length-1]
}

export function isEmpty(object) {
  return Object.keys(object).length === 0
}

export function removeEmptyArrays(collection) {
  if (Array.isArray(collection))
    return collection.filter(item => !Array.isArray(item) || item.length > 0)

  const result = {...collection}
  Object.keys(result).forEach(k => {
    if (Array.isArray(result[k]) && result[k].length === 0)
      delete result[k]
  })
  return result
}

export function mergeCollectionAndDefaults(collection, defaults, key) {
  collection = collection || []
  defaults = defaults || []

  return defaults.map(d => ({...d, ...collection.find(o => `${o[key]}` === `${d[key]}`)}))
    .concat(collection.filter(o => !defaults.some(d => `${d[key]}` === `${o[key]}`)))
}

export function mergeStatePropsAndDefaults(state, props, defaults, key) {
  props = props || []
  state = state || props

  const collection = state.map(s => ({...props.find(p => `${p[key]}` === `${s[key]}`), ...s}))

  return mergeCollectionAndDefaults(collection, defaults, key)
}

export function deepRemoveBlanks(collection) {
  if (!collection)
    return collection === 0 ? 0 : null

  if (Array.isArray(collection)) {
    const result = collection.map(item => deepRemoveBlanks(item)).filter(item => item || item === 0)
    return result.length === 0 ? null : result
  }

  if (typeof collection === 'object') {
    const result = {}
    Object.keys(collection).forEach(k => {
      const item = deepRemoveBlanks(collection[k])
      if (item || item === 0)
        result[k] = item
    })
    return Object.keys(result).length === 0 ? null : result
  }

  return collection
}
