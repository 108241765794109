import ActionSet from './ActionSet'

export class SnackbarActionSet extends ActionSet{

  static initialState = {
    message: '',
    status: ''
  }

  static setMessageAndStatus(creator, reducer){
    creator(({message, status}) => {
      return {
        type: this.SET_MESSAGE_AND_STATUS,
        payload: { message, status }
      }
    })

    reducer({
      [this.SET_MESSAGE_AND_STATUS]: (state, {message, status}) => {
        return {...state, message, status }
      }
    })
  }

  static clear(creator, reducer){
    creator(message => {
      return {
        type: this.CLEAR
      }
    })

    reducer({
      [this.CLEAR]: (state) => {
        return {...state, message: ''}
      }
    })
  }

  static notice(creator, reducer){
    creator(message => dispatch => dispatch(this.setMessageAndStatus({message, status: 'notice'})))
    reducer({})
  }

  static warning(creator, reducer){
    creator(message => dispatch => dispatch(this.setMessageAndStatus({message, status: 'warning'})))
    reducer({})
  }

  static error(creator, reducer){
    creator(message => dispatch => dispatch(this.setMessageAndStatus({message, status: 'error'})))
    reducer({})
  }

  static success(creator, reducer){
    creator(message => dispatch => dispatch(this.setMessageAndStatus({message, status: 'success'})))
    reducer({})
  }

  // Info is an alias for notice and alert
  static info  = SnackbarActionSet.notice
  static alert = SnackbarActionSet.notice
  static show  = SnackbarActionSet.notice
}

export default new SnackbarActionSet()