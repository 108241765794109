export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    return reducer ? reducer(state, action.payload): state;
  };
}

export function queueRequest({requests}, request){
  return [...requests, request]
}

export function finishRequest({requests}, request){
  return requests.filter(r => r !== request)
}