import Nonce from './Nonce'

class LinkedInService {
  constructor() {
    this.authPopup = null
    this.state = null
    this.callbacks = new Set()
  }

  receiveAuthMessage = (event) => {
    if(event.source !== this.authPopup) {
      return
    }

    let { state } = event.data

    if(this.state !== state) {
      this.replyToListeners({ error: 'state_mismmatch', errorDescription: 'Potential CSRF attack' })
      return
    }

    // TODO maybe we want to standardize this response over multiple Auth providers
    let authHash = {
      ...this.authHashFromReply(event.data),
      redirectUri: this.redirectUri()
    }
    this.replyToListeners(authHash)
    this.authPopup.close()
  }

  authHashFromReply = (data) => {
    let { error, error_description, code } = data
    if(error) {
      return { error, errorDescription: error_description }
  } else {
      return { authCode: code }
    }
  }

  replyToListeners = (payload) => {
    this.callbacks.forEach((callback) => { callback(payload) })
    this.callbacks.clear()
    window.removeEventListener('message', this.receiveAuthMessage)
  }

  authorize = (callback) => {
    this.callbacks.add(callback)
    if(!this.authPopup || this.authPopup.closed) {
      window.addEventListener('message', this.receiveAuthMessage, false)

      // Do all the dancing (Alex) and hopefully get back with an access_code
      this.state = Nonce.generate()
      this.authPopup = window.open(this.authUrl(this.state), 'LinkedInAuth')
    }
    this.authPopup.focus()
  }

  authUrl = (state) => {
    // TODO: get these values from some configuration
    let authorizationUrl = process.env.REACT_APP_LINKED_AUTHORIZATION_URL
    let clientId = process.env.REACT_APP_LINKED_CLIENT_ID
    let scope = "r_basicprofile,r_emailaddress"
    let authUrl = `${authorizationUrl}?response_type=code&client_id=${clientId}&redirect_uri=${this.redirectUri()}&state=${state}&scope=${scope}`
    return authUrl
  }

  redirectUri = () => {
    let url = new URL(window.location)
    return `${url.origin}/linkedin/callback`
  }
}

const LinkedInServiceInstance = new LinkedInService()
export { LinkedInServiceInstance as LinkedInService }