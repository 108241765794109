import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'
import { finishRequest, queueRequest } from 'utils'

export class TransactionActionSet extends ActionSet{
  static initialState = {
    transactions: null,
    transaction: {},
    requests: [],
    errors: {}
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator(({dealSheetId}) => {
      return {
        type: this.INDEX,
        promise: API.Transactions.index({dealSheetId})
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request), errors: {...state.error, index: null}}
      },
      [this.INDEX_SUCCESS]: (state, { request, result: {data: transactions}}) => {
        return {...state, requests: finishRequest(state, request), transactions}
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        return {...state, transactions: [], requests: finishRequest(state, request), errors: {...state.errors, index: error}}
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.UPDATE,
        promise: API.Transactions.update(params)
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.UPDATE_SUCCESS]: (state, { request }) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, update: error } }
      }
    })
  }

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.CREATE,
        promise: API.Transactions.create(params)
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.CREATE_SUCCESS]: (state, { request }) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, create: null } }
      },
      [this.CREATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, create: error } }
      }
    })
  }

  static destroy(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.DESTROY,
        promise: API.Transactions.destroy(params)
      }
    })

    reducer({
      [this.DESTROY_REQUEST]: state => {
        return {...state, transaction: {}, errors: {...state.errors, create: null, update: null } }
      },
      [this.DESTROY_SUCCESS]: (state, { result }) => {
        return {...state, errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_FAILURE]: (state, { request, error }) => {
        return {...state, errors: {...state.errors, destroy: error } }
      }
    })
  }

  static createEvent(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((transactionId, eventParams) => {
      const params = {id: transactionId, ...eventParams}
      return {
        type: this.CREATE_EVENT,
        promise: API.Transactions.events(params)
      }
    })

    reducer({
      [this.CREATE_EVENT_REQUEST]: (state, { request }) => {
        return { ...state, requests: queueRequest(state, request), errors: {...state.errors, createEvent: null} }
      },
      [this.CREATE_EVENT_SUCCESS]: (state, { request, result: {data: transaction } }) => {
        let transactions = state.transactions && state.transactions.map(t => t.id === transaction.id ? transaction : t)
        return { ...state, requests: finishRequest(state, request), transactions }
      },
      [this.CREATE_EVENT_FAILURE]: (state, { request, error }) => {
        return { ...state, requests: finishRequest(state, request), errors: {...state.errors, createEvent: error} }
      }
    })
  }

}

export default new TransactionActionSet()