import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class OrganisationActionSet extends ActionSet{

  static initialState = {
    organisation:  {},
    requests:      [],
    searchResults: [],
    totalPages:    0,
    errors: {
      update:  null,
      show:    null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.UPDATE,
        promise: API.Organisation.update(params)
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.UPDATE_SUCCESS]: (state, { request, result: { data: organisation } }) => {
        const requests = finishRequest(state, request)
        return {...state, organisation, requests, errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, update: error } }
      }
    })
  }

  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.Organisation.show(params)
      }
    })

    reducer({
      [this.SHOW_REQUEST]: state => {
        return {...state, country: { }, errors: {...state.errors, create: null, update: null } }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: organisation } }) => {
        return {...state, organisation, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { error }) => {
        return {...state, errors: {...state.errors, show: error } }
      }
    })
  }
}

export default new OrganisationActionSet()