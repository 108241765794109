import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { TokenActions } from './'

export class InvestorActionSet extends ActionSet{

  static initialState = {
    investor: {},
    requests: [],
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null,
      show:    null,
      createEvent: null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return async(dispatch) => {
        const investorAccount = await dispatch({
          type: this.UPDATE,
          promise: API.Investors.update(params)
        })
        dispatch(TokenActions.updateInvestorState(investorAccount.data))
      }

    })

    reducer({
      [this.UPDATE_REQUEST]: (state, request) => {
        return {...state, requests: [...state.requests, request] }
      },
      [this.UPDATE_SUCCESS]: (state, { request, result: { data: investor } } ) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, investor, errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, update: error } }
      }
    })
  }

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return async(dispatch) => {
        const investorAccount = await dispatch({
          type: this.CREATE,
          promise: API.Investors.update(params)
        })
        dispatch(TokenActions.updateInvestorState(investorAccount.data))
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, request) => {
        return {...state, requests: [...state.requests, request] }
      },
      [this.CREATE_SUCCESS]: (state, { request }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, create: null } }
      },
      [this.CREATE_FAILURE]: (state, { request, error}) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, create: error } }
      }
    })
  }

  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.Investors.show(params)
      }
    })

    reducer({
      [this.SHOW_REQUEST]: state => {
        return {...state, investor: {}, errors: {...state.errors, createEvent: null, create: null, update: null } }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: investor } }) => {
        return {...state, investor, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { request, error }) => {
        return {...state, errors: {...state.errors, show: error } }
      }
    })
  }

  static createEvent(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((investorId, eventParams) => {
      return async (dispatch) => {
        const params = {id: investorId, ...eventParams}
        const investorAccount = await dispatch({
          type: this.CREATE_EVENT,
          promise: API.Investors.events(params)
        })
        dispatch(TokenActions.updateInvestorState(investorAccount.data))
      }
    })

    reducer({
      [this.CREATE_EVENT_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, createEvent: null} }
      },
      [this.CREATE_EVENT_SUCCESS]: (state, { request, result }) => {
        const { data: investor } = result
        const requests = state.requests.filter(r => r !== request)

        const newinvestor = (state.investor.id === investor.id) ? investor : state.investor
        return { ...state, investor: newinvestor, requests }
      },
      [this.CREATE_EVENT_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: {...state.errors, createEvent: error} }
      }
    })
  }
}

export default new InvestorActionSet()