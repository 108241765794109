export function debounce(fnc, wait, context){
  fnc = fnc.bind(context)
  let timer, promise, resolve

  return function () {
    // When we invoke this function
    // We want to call the wrapped function as soon as it has been uncalled
    // for at least `wait`
    promise = promise || new Promise(r => resolve = r)
    //If we have a callback queued. Cancel it due
    //to us calling the function again within the window
    clearTimeout(timer)

    timer = setTimeout(() => {
      resolve(fnc(...arguments))
      promise = null
    }, wait || 250)
    return promise
  }
}

export function throttle(fnc, wait, context){
  let isQueued = false
  let promise, resolve

  return function () {
    if(isQueued) return promise
    isQueued = true
    promise = new Promise(r => resolve = r)
    setTimeout(() => {
      resolve(fnc.bind(context)(...arguments))
      isQueued = false
      promise = null
    }, wait || 250)
    return promise
  }
}

export function timeout(time=0){
  return new Promise(resolve => setTimeout(resolve, time))
}

export function future(){
  let onResolve, onReject
  const promise = new Promise((r, c) => {[onResolve, onReject] = [r,c]})
  return { promise, onResolve, onReject }
}