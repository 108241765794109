import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class UserActionSet extends ActionSet{

  static initialState = {
    users: [],
    user:    {},
    requests: [],
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null,
      show:    null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]


  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(page => (dispatch, getState) => {
      page = page || getState().users.page
      return dispatch({
        type: this.INDEX,
        promise: API.Users.index({options: {page: { number: page }}})
      })
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, index: null, update: null }}
      },
      [this.INDEX_SUCCESS]: (state, { request, result: { data: users, meta: { totalPages } } }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, users, totalPages }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, index: error } }
      }
    })
  }

  static search(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({filter, page, pageSize, order, resultsNameSpace = 'search'} = {}) => {
      return {
        type: this.SEARCH,
        promise: API.Users.index({options: {page: { number: page, size: pageSize }, filter, order}}),
        payload: { resultsNameSpace }
      }
    })

    reducer({
      [this.SEARCH_REQUEST]: (state, { request, requestPayload: { resultsNameSpace } }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, [resultsNameSpace]: null, update: null }}
      },
      [this.SEARCH_SUCCESS]: (state, { request, result: { data: results, meta: { totalPages } }, requestPayload: { resultsNameSpace } }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, [resultsNameSpace]: {results, totalPages} }
      },
      [this.SEARCH_FAILURE]: (state, { request, error, requestPayload: { resultsNameSpace }  }) => {
        const requests = state.requests.filter(r => r !== request)
        return {...state, requests, errors: {...state.errors, [resultsNameSpace]: error } }
      }
    })
  }

  static saveSearchState(creator, reducer){
    creator((state) => {
      return {
        type: this.SAVE_SEARCH_STATE,
        payload: state
      }
    })

    reducer({
      [this.SAVE_SEARCH_STATE]: (state, searchState) => {
        return {...state, searchState}
      }
    })
  }

  static clearSearchResults(creator, reducer){
    creator(() => {
      return {
        type: this.CLEAR_SEARCH_RESULTS
      }
    })

    reducer({
      [this.CLEAR_SEARCH_RESULTS]: state => {
        return {...state, searchResults: UserActionSet.initialState.searchResults}
      }
    })
  }

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.CREATE,
        promise: API.Users.create(params)
      }
    })

    reducer({
      [this.CREATE_SUCCESS]: (state) => {
        return {...state, errors: {...state.errors, create: null } }
      },
      [this.CREATE_FAILURE]: (state, { request, error}) => {
        return {...state, errors: {...state.errors, create: error }  }
      }
    })
  }

  static invite(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.INVITE,
        promise: API.Users.invite(params)
      }
    })

    reducer({
      [this.INVITE_SUCCESS]: (state) => {
        return {...state, errors: {...state.errors, invite: null } }
      },
      [this.INVITE_FAILURE]: (state, { request, error}) => {
        return {...state, errors: {...state.errors, invite: error }  }
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.UPDATE,
        promise: API.Users.update(params)
      }
    })

    reducer({
      [this.UPDATE_SUCCESS]: (state, { request, result: { data: user }}) => {
        return {...state, user, errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, error}) => {
        return {...state, errors: {...state.errors, update: error } }
      }
    })
  }

  static destroy(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.DESTROY,
        promise: API.Users.destroy(params)
      }
    })

    reducer({
      [this.DESTROY_SUCCESS]: (state) => {
        return {...state, errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_FAILURE]: (state, {error}) => {
        return {...state, errors: {...state.errors, destroy: error } }
      }
    })
  }

  static changeAccountType(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.CHANGE_ACCOUNT_TYPE,
        promise: API.Users.changeAccountType(params)
      }
    })

    reducer({
      [this.CHANGE_ACCOUNT_TYPE_SUCCESS]: (state, { request, result: { data: user }}) => {
        return {...state, user, errors: {...state.errors, changeAccountType: null } }
      },
      [this.CHANGE_ACCOUNT_TYPE_FAILURE]: (state, { request, error}) => {
        return {...state, errors: {...state.errors, changeAccountType: error } }
      }
    })
  }

  static generate2faSecret(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.GENERATE2FA_SECRET,
        promise: API.Users.generate2faSecret(params)
      }
    })

    reducer({
      [this.GENERATE2FA_SECRET_SUCCESS]: (state, { request, result: { data: secret }}) => {
        return {...state, errors: {...state.errors, generate2faSecret: null } }
      },
      [this.GENERATE2FA_SECRET_FAILURE]: (state, { request, error}) => {
        return {...state, errors: {...state.errors, generate2faSecret: error } }
      }
    })
  }

  static updateTncs(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => async(dispatch) => {
      await dispatch({
        type: this.UPDATE_TNCS,
        promise: API.Users.updateTncs(params)
      })
    })

    reducer({
      [this.UPDATE_TNCS_SUCCESS]: (state) => {
        return {...state, errors: {...state.errors, updateTncs: null } }
      },
      [this.UPDATE_TNCS_FAILURE]: (state, { request, error}) => {
        return {...state, errors: {...state.errors, updateTncs: error } }
      }
    })
  }

  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.Users.show(params)
      }
    })

    reducer({
      [this.SHOW_SUCCESS]: (state, { result: { data: user } }) => {
        return {...state, user, errors: {...state.errors, show: null, create: null, update: null } }
      },
      [this.SHOW_FAILURE]: (state, { request, error }) => {
        return {...state, errors: {...state.errors, show: error } }
      }
    })
  }
}

export default new UserActionSet()