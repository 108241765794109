import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class NominationActionSet extends ActionSet{

  static initialState = {
    nomination: null,
    nominations: [],
    requests: [],
    errors: {}
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static resetErrors(creator, reducer, constants){
    creator(() => {
      return {
        type: this.RESET_ERRORS,
        payload: {}
      }
    })

    reducer({
      [this.RESET_ERRORS]: (state, _ ) => {
        return {...state, errors: {} }
      }
    })
  }

  static index(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId, pageIndex, order) => (dispatch, getState) => {
      const page = pageIndex || 1
      const options = {
        page: { number: page, size: 10 },
        order
      }

      return dispatch({
        type: this.INDEX,
        promise: API.Nominations.index({ dealSheetId, options })
      })
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors } }
      },
      [this.INDEX_SUCCESS]: (state, { request, result }) => {
        const { data: nominations, meta: { totalPages } } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, nominations: nominations, totalPages, errors: {...state.errors, index: null} }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, index: error } }
      }
    })
  }

  static show(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({dealSheetId}) => {
      return {
        type: this.SHOW,
        promise: API.Nominations.show({dealSheetId})
      }
    })

    reducer({
      [this.SHOW_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.SHOW_SUCCESS]: (state, { result: { data: nomination }, request }) => {
        return {...state, requests: finishRequest(state, request), nomination, errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), nomination: null, errors: {...state.errors, show: error } }
      }
    })
  }

  static create(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((nominationParams) => {
      return {
        type: this.CREATE,
        promise: API.Nominations.create(nominationParams)
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.CREATE_SUCCESS]: (state, { result: { data: nomination }, request }) => {
        return {...state, requests: finishRequest(state, request), nomination, errors: {...state.errors, create: null } }
      },
      [this.CREATE_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, create: error } }
      }
    })
  }

  static destroy(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({id}) => {
      return {
        type: this.DESTROY,
        promise: API.Nominations.destroy({id})
      }
    })

    reducer({
      [this.DESTROY_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.DESTROY_SUCCESS]: (state, { result: { data: nomination }, request }) => {
        return {...state, requests: finishRequest(state, request), nomination: null, errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_FAILURE]: (state, { error, request}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, destroy: error } }
      }
    })
  }

}

export default new NominationActionSet()