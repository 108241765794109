import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class SocialMediaLinksActionSet extends ActionSet{

  static initialState = {
    partners: [],
    requests:     [],
    errors: {
      bulkUpdate:  null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static bulkUpdate(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({id, socialMediaLinks}) => {
      return {
        type: this.BULK_UPDATE,
        promise: API.DealSheets.updateSocialMediaLinks({id, socialMediaLinks})
      }
    })

    reducer({
      [this.BULK_UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.BULK_UPDATE_SUCCESS]: (state, { request }) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, bulkUpdate: null } }
      },
      [this.BULK_UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, bulkUpdate: error } }
      }
    })
  }
}
export default new SocialMediaLinksActionSet()