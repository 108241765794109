import { bindActionCreators } from 'redux'
import { createReducer } from 'utils'

export default class ActionSet{

  static actionSetSequence = 0

  constructor(){
    this.initialState = this.constructor.initialState
    this.reducerSets = []
    this.sequenceNo = (ActionSet.actionSetSequence += 1)
    this.actionNames.forEach(actionName => {
      try{
        if(actionName !== '__proto__'){
          this.createAction(actionName)
        }
      }catch(err){
        throw new Error(`Error while creating action ${actionName} ${err}`)
      }
    })
    this.actions = {}
    this.actionNames.forEach(name => {
      this.actions[name] = this[name]
    })

    this.combineReducers()
  }

  combineReducers(){
    let reducers = {}
    this.reducerSets.forEach(set => reducers = {...reducers, ...set})
    if(reducers[undefined]){
      throw new Error('Reducer with undefined type defined. Did you forget to define a constant?')
    }
    this.reducerNames = Object.keys(reducers)
    this.reducers = createReducer(this.initialState, reducers)
  }

  extractReducers(defaultName, reducers){
    if(typeof(reducers) === 'function'){
      return { [defaultName]: reducers }
    }else{
      return reducers
    }
  }

  applyConstantsMiddleware(constants){
    this.constructor.constantsMiddleware.forEach(mw => constants = mw(constants))
    return constants
  }

  createAction(actionName){
    const actionCreator   = this.constructor[actionName]
    const actionConstant  = this.constantize(actionName)
    const setName         = this.constantize(this.constructor.name)

    this.defineConstant(actionConstant)

    const dependencies = { creator: null, reducer: null}

    let actionConstants = (...suffixes) => suffixes.forEach(suffix => this.defineConstant(`${actionConstant}_${suffix}`, `${this.sequenceNo}.${setName}.${actionConstant}.${suffix}`))
    if(this.constructor.constantsMiddleware)
      actionConstants = this.applyConstantsMiddleware(actionConstants)

    actionCreator.bind(this)(
      creatorFunc => dependencies.creator = creatorFunc,
      reducers    => dependencies.reducer = this.extractReducers(actionConstant, reducers),
      actionConstants
    )

    if(!dependencies.creator){
      throw new Error(`Action ${actionName} does not define a creator`)
    }
    if(!dependencies.reducer){
      throw new Error(`Action ${actionName} does not define a reducer`)
    }

    this[actionName] = dependencies.creator
    this.reducerSets.push(dependencies.reducer)
  }

  defineConstant(key, value){
    const asConst = this.constantize(key)
    const asConstValue = `${this.sequenceNo}.${this.constantize(this.constructor.name)}.${asConst}`
    this[asConst] = value || asConstValue
  }

  constantize(value){
    return `${value}`.replace(/[a-z][A-Z]/g, (str) => `${str[0].toUpperCase()}_${str[1].toUpperCase()}`).toUpperCase()
  }

  get actionNames(){
    const propertyNames = Object.getOwnPropertyNames(this.constructor).concat(['resetState', 'globalResetState'])
    return propertyNames.filter(propName => {
      try{
        const value = this.constructor[propName]
        return typeof value === 'function'
      } catch(err){}
      return false
    })
  }

  bindActions(context, namespace){
    let actions = context.actions = context.actions || {}
    if(!context.props.dispatch){
      const scope = this.constructor.name
      const subject = scope.replace('ActionSet', '')
      throw new Error(`Cannot bind to ${scope}. Component is not connected (has no dispatch prop).
Maybe you are missing something like:
  import { connect } from 'react-redux'
  export default connect(({${subject.toLowerCase()}}) => ${subject.toLowerCase()})(${subject})`
      )
    }
    if(namespace){
      actions = actions[namespace] = actions[namespace] || {}
    }
    Object.assign(actions, bindActionCreators(this.actions, context.props.dispatch))
  }

  static resetState(creator, reducer){
    creator(() => {
      return {
        type: this.RESET_STATE
      }
    })

    reducer({
      [this.RESET_STATE]: () => this.initialState
    })
  }

  static globalResetState(creator, reducer){
    creator(() => {
      return {
        type: 'GLOBAL_RESET_STATE'
      }
    })

    reducer({
      GLOBAL_RESET_STATE: (state) => this.persistGlobalReset ? state : this.initialState
    })
  }
}