import { TokenActions } from 'actionsets'

export default function authenticationMiddleware() {
  return (next) => (action) => {
    try{
      if(action.payload.error.status === 403){
        return next(
          dispatch => dispatch(TokenActions.verify())
        ).then(()  => next(action))
         .catch(() => next(dispatch => dispatch(TokenActions.destroy())))
      }
    }catch(_){ /* pass */}
    return next(action)
  }
}