import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class ServiceActionSet extends ActionSet {

  static initialState = {
    services: [],
    requests:  [],
    errors: {
      index: null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return {
        type: this.INDEX,
        promise: API.Services.index()
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, sites: [], errors: {...state.errors, index: null }, requests: queueRequest(state, request) }
      },
      [this.INDEX_SUCCESS]: (state, { result: { data: services  }, request }) => {
        const requests = finishRequest(state, request)
        return {...state, services, errors: {...state.errors, index: null }, requests }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = finishRequest(state, request)
        return {...state, errors: {...state.errors, index: error }, requests }
      }
    })
  }
}


export default new ServiceActionSet()