import pluralize from 'pluralize'

export function createActionNameList(namespace){
  const actionTypes = ['', 'REQUEST', 'SUCCESS', 'FAILURE'];
  return (...constants) => {
    return constants.reduce((acc, constant) => {
      const constantText = `${namespace}.${constant}`
      actionTypes.forEach((type) => {
        acc[[constant, type].filter(x => x).join('_').toUpperCase()] = [constantText, type].filter(x => x).join('.')
      })
      return acc;
    }, {});
  }
}

export function createNamespacedConstants(namespace){
  return (...constants) => {
    return constants.reduce((acc, constant) => {
      const constantText          = `${namespace}.${constant}`
      acc[constant.toUpperCase()] = constantText
      return acc;
    }, {});
  }
}

export function camelize(underscored){
  return underscored.trim().split('_').map((s, index) => (index === 0)? s : capitalize(s)).join("")
}

export function humanize(camelized){
  return camelized ? camelized.split(/(?=[A-Z0-9])|_/).map(s => s[0].toUpperCase() + s.slice(1)).join(" ") : ''
}

export function underscore(camelized){
  return camelized.split(/(?=[A-Z])|\s/).map(s => s[0].toLowerCase() + s.slice(1)).join("_")
}

export function controllerize(camelized){
  return pluralize(underscore(camelized))
}

export function displayDate(date){
  let d = date
  if (typeof d === 'string')
    d = Date.parse(date)
  if (typeof d === 'number')
    d = new Date(date)

  return `${d.getFullYear()}-${("0" + d.getMonth()).slice(-2)}-${d.getDate()}`
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createEnum(...constants){
  let i = 0;
  return constants.reduce((acc, constant) => {
    acc.values[constant] = i;
    acc.names[i] = constant
    i += 1
    return acc;
  }, {names: [], values: {}});
}

export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant.toUpperCase()] = constant;
    return acc;
  }, {});
}

export function uuid() {
  let bits = (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(16))
  bits[8] = (bits[8] & 0x3F) | 0x80
  bits[6] = (bits[6] & 0x0F) | 0x40
  let i = 0
  return ([1e7]+-1e3+-1e3+-1e3+-1e11).replace(/[018][018]/g, d => {
    let x = bits[i++].toString(16)
    if(x.length === 1) {
      x = '0' + x
    }
    return x
  })
}
export function isBlank(aString) {
  return !aString || aString.trim().length === 0
}

export function displayNumber(number, {thousandsSeparator = ' ', decimalSeparator = '.'} = {}) {
  if (typeof number === 'number')
    number = number.toString()

  number = normalizeNumber(number, {thousandsSeparator, decimalSeparator})

  let [left = '', right = ''] = number.split('.')
  let result = ''

  for (let i = left.length - 1; i >= 0; i--) {
    if (i !== left.length - 1 && (left.length - 1 - i) % 3 === 0) {
      result = thousandsSeparator + result
    }
    result = left[i] + result
  }

  if (right.length)
    result += decimalSeparator + right
  else if (number.includes('.'))
    result += decimalSeparator

  return result
}

export function normalizeNumber(value, {thousandsSeparator = ' ', decimalSeparator = '.'} = {}) {
  return value.split(thousandsSeparator).join('').replace(decimalSeparator, '.')
}
