import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from '../utils/ActionsetUtils'
import {camelize} from 'utils'

export class DocumentActionSet extends ActionSet{

  static initialState = {
    issuerTncs: {},
    investorTncs: {},
    amlInfo: {},
    requests: [],
    errors: {
      show: null,
      update: null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.Documents.show(params),
        payload: params
      }
    })

    reducer({
      [this.SHOW_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request), errors: {...state.errors, show: null } }
      },
      [this.SHOW_SUCCESS]: (state, { request, requestPayload: { name }, result: { data: document } }) => {
        return {...state, [camelize(name)]: document, requests: finishRequest(state, request), errors: {...state.errors, show: null } }
      },
      [this.SHOW_FAILURE]: (state, { request, requestPayload: { name }, error }) => {
        return {...state, [camelize(name)]: null, requests: finishRequest(state, request), errors: {...state.errors, show: error } }
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.UPDATE,
        promise: API.Documents.update(params),
        payload: params
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.UPDATE_SUCCESS]: (state, { request, requestPayload: { name }, result: { data: document } }) => {
        return {...state, [camelize(name)]: document, requests: finishRequest(state, request), errors: {...state.errors, update: null } }
      },
      [this.UPDATE_FAILURE]: (state, { request, requestPayload: { name }, error}) => {
        return {...state, [camelize(name)]: null, requests: finishRequest(state, request), errors: {...state.errors, update: error } }
      }
    })
  }
}

export default new DocumentActionSet()