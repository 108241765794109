import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class RatesActionSet extends ActionSet{

  static initialState = {
    rates: {}
  }


  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  get persistGlobalReset() {
    return true
  }

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(message => {
      return {
        type: this.INDEX,
        promise: API.Rates.index()
      }
    })

    reducer({
      [this.INDEX_SUCCESS]: (state, {result: { rates: ratesList }}) => {
        const rates = {}
        ratesList.forEach(({id_currency, name, rate}) => {
          rates[id_currency] = { name, rate, ticker: id_currency }
        })
        return {...state, rates}
      }
    })
  }
}

export default new RatesActionSet()