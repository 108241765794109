export class DialogHandler {
  constructor(context, dialogName = 'dialog') {
    this.context = context
    this.dialogName = dialogName

    context.state[this.openStateName] = false
  }

  get openStateName() {
    return `${this.dialogName}Open`
  }

  get state() {
    return this.context.state[this.dialogName] || {}
  }

  setState = (newState) => {
    this.context.setState({[this.dialogName]: {...this.state, ...newState}})
  }

  get isOpen() {
    return !!this.context.state[this.openStateName]
  }

  close = result => {
    this.context.setState({[this.openStateName]: false})

    if (this.resolve) {
      this.resolve(result)
      this.resolve = null
    }
  }

  onCancel = () => {
    this.close(undefined)
  }

  onConfirm = (value = true) => {
    this.close(value)
  }

  show = (props) => {
    this.showProps = props
    this.context.setState({[this.openStateName]: true})
    return new Promise(resolve => {
      this.resolve = resolve
    })
  }

  clearError = () => {
    this.setState({error: null})
  }

  mergedProps(otherProps) {
    const myProps = this.props
    return {
      ...myProps,
      ...otherProps,
      onConfirm: async(...props) => {
        this.clearError()
        try {
          otherProps.onConfirm && await otherProps.onConfirm(...props)
          myProps.onConfirm(...props)
        }
        catch(err) {
          this.setState({error: err.message})
        }
      },
      onCancel: async(...props) => {
        this.clearError()
        try {
          otherProps.onCancel && await otherProps.onCancel(...props)
          myProps.onCancel(...props)
        }
        catch(err) {
          this.setState({error: err.message})
        }
      }
    }
  }

  get props() {
    return {
      open: this.isOpen,
      onCancel: this.onCancel,
      onConfirm: this.onConfirm,
      canCancel: true,
      canConfirm: true,
      ...this.showProps
    }
  }
}