import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from 'utils'

export class DownloadActionSet extends ActionSet{

  static initialState = {
    downloads: [],
    requests:     [],
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null,
      show:    null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({resourceId, resourceType}) => {
      return {
        type: this.INDEX,
        promise: API.Downloads.index({resourceId, resourceType}),
      }
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return {...state, downloads: [], errors: {...state.errors, destroy: null, create: null, update: null }, requests: queueRequest(state, request) }
      },
      [this.INDEX_SUCCESS]: (state, { result: { data: downloads  }, request }) => {
        const requests = finishRequest(state, request)
        return {...state, downloads, errors: {...state.errors, index: null }, requests }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const requests = finishRequest(state, request)
        return {...state, errors: {...state.errors, index: error }, requests }
      }
    })
  }

  static bulkUpdate(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({resourceId, resourceType, downloads, removedDocumentsFor = []}) => {
      return {
        type: this.BULK_UPDATE,
        promise: API.Downloads.bulkUpdate({resourceId, resourceType, downloads, removedDocumentsFor})
      }
    })

    reducer({
      [this.BULK_UPDATE_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request)  }
      },
      [this.BULK_UPDATE_SUCCESS]: (state, { request }) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, bulkUpdate: null } }
      },
      [this.BULK_UPDATE_FAILURE]: (state, { request, error}) => {
        const requests = finishRequest(state, request)
        return {...state, requests, errors: {...state.errors, bulkUpdate: error } }
      }
    })
  }
}
export default new DownloadActionSet()