import { ProtectedJsonAPI } from '.'

export const Downloads = ProtectedJsonAPI.extend(
  '',
  {
    index:      endpoint => endpoint.path(({resourceType, resourceId, isPrivate}) => `${resourceType}/${resourceId}/downloads`).method('get'),
    create:     endpoint => endpoint.useFormData(true).path(({resourceType, resourceId}) => `${resourceType}/${resourceId}/downloads`).method('post'),
    update:     endpoint => endpoint.useFormData(true).path(({id}) => `/downloads/${id}`).method('put'),
    bulkUpdate: endpoint => endpoint.useFormData(true).path(({resourceType, resourceId}) => `${resourceType}/${resourceId}/downloads/bulk`).method('put'),
    destroy:    endpoint => endpoint.path(({id}) => `/downloads/${id}`).method('delete'),
  }
)