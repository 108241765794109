import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import { queueRequest, finishRequest } from '../utils/ActionsetUtils'

export class PersonKycActionSet extends ActionSet{

  static initialState = {
    issuerTncs: {},
    investorTncs: {},
    amlInfo: {},
    requests: [],
    errors: {
      show: null,
      update: null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static sendInstructions(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SEND_INSTRUCTIONS,
        promise: API.PersonKyc.sendInstructions(params),
        payload: params
      }
    })

    reducer({
      [this.SEND_INSTRUCTIONS_REQUEST]: (state, { request }) => {
        return {...state, requests: queueRequest(state, request) }
      },
      [this.SEND_INSTRUCTIONS_SUCCESS]: (state, { request, requestPayload: { name } }) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, sendInstructions: null } }
      },
      [this.SEND_INSTRUCTIONS_FAILURE]: (state, { request, requestPayload: { name }, error}) => {
        return {...state, requests: finishRequest(state, request), errors: {...state.errors, sendInstructions: error } }
      }
    })
  }
}

export default new PersonKycActionSet()