import { ProtectedJsonAPI } from '.'

export const ThirdParties = ProtectedJsonAPI.extend(
  '/third_parties',
  {
    index:   endpoint => endpoint,
    search:  endpoint => endpoint.path('search'),
    show:    endpoint => endpoint.path(({id}) => `${id}`),
    create:  endpoint => endpoint.method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => `${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `${id}`)
  }
)