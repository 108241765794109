import { ProtectedJsonAPI } from '.'

export const Issuers = ProtectedJsonAPI.extend(
  '/users',
  {
    index:   endpoint => endpoint.base('/issuer_accounts').method('get'),
    search:  endpoint => endpoint.base('/issuer_accounts/search').method('get'),
    show:    endpoint => endpoint.path(({id}) => `${id}/issuer_account`),
    update:  endpoint => endpoint.useFormData(true).method('put').path(({userId}) => `${userId}/issuer_account`),
    events:  endpoint => endpoint.method('post').path(({id}) => `${id}/issuer_account/events`),
  }
)