import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'

export class DealSheetActionSet extends ActionSet{
  static initialState = {
    // TODO maybe store them indexed by id and store just
    // the referenced id in the dealSheet field
    dealSheet: {
      status: 'draft',
      teamMembers: [],
      services: [],
      downloads: [],
      tokenDistributions: [],
      fundsUses: [],
      legalAdvisers: [{}],
      companyInfo: {},
      allowedCountries: '',
      socialMediaLinks: [],
      isRestrictedToCountries: false,
      partners: [],
      galleryImages: [],
      links: [],
      dealRound: 'seed',
      typeOfIssuance: 'product'
    },
    dealSheetDetails: {},
    dealSheets: [],
    requests: [],
    issuers: [],
    permittedViewers: {
      totalPages: 0
    },
    restrictedViewers: {
      totalPages: 0
    },
    validationErrors: {},
    errors: {
      index:   null,
      create:  null,
      update:  null,
      destroy: null,
      show:    null,
      loadIssuers: null,
      createEvent: null,
      loadViewers: null,
      permitViewers: null,
      restrictViewers: null,
      removeViewer: null,
      'wallet.createEvent': null,
      'wallet.load': null,
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static newRecord(creator, reducer, constants){
    creator(params => {
      return {
        type: this.NEW_RECORD,
        payload: { dealSheet: DealSheetActionSet.initialState.dealSheet }
      }
    })

    reducer({
      [this.NEW_RECORD]: (state, { dealSheet }) => {
        return { ...state, dealSheet, validationErrors: {}, errors: {} }
      }
    })
  }

  static index(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((filter={}, { page=1, size }={}, order={}) => (dispatch, getState) => {
      const options = {
        options: {
          page: { number: page, size},
          filter,
          order
        }
      }

      return dispatch({
        type: this.INDEX,
        promise: API.DealSheets.index(options)
      })
    })

    reducer({
      [this.INDEX_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, index: null } }
      },
      [this.INDEX_SUCCESS]: (state, { request, result }) => {
        const { data: dealSheets, meta: { totalPages } } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, dealSheets: dealSheets, dealSheetDetails: DealSheetActionSet.initialState.dealSheetDetails, totalPages }
      },
      [this.INDEX_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, index: error } }
      }
    })
  }

  static fetchDetails(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId) => ({
      type: this.FETCH_DETAILS,
      promise: API.DealSheets.fetchDetails({id: dealSheetId})
    }))

    reducer({
      [this.FETCH_DETAILS_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, index: null } }
      },
      [this.FETCH_DETAILS_SUCCESS]: (state, { request, result }) => {
        const { data: dealSheetDetail } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, dealSheetDetails: {...state.dealSheetDetails, [dealSheetDetail.id]: dealSheetDetail} }
      },
      [this.FETCH_DETAILS_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, fetchDetails: error } }
      }
    })
  }

  static search(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({page, pageSize, filter, resultsNameSpace = 'search', clear} = {}) => (dispatch) => {
      return dispatch({
        type: this.SEARCH,
        promise: API.DealSheets.index({options: { page: { number: page, size: pageSize }, filter }}),
        payload: { resultsNameSpace, clear }
      })
    })

    reducer({
      [this.SEARCH_REQUEST]: (state, { request, requestPayload: { resultsNameSpace } }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, [resultsNameSpace]: null}, [resultsNameSpace]: {...state[resultsNameSpace], loading: true} }
      },
      [this.SEARCH_SUCCESS]: (state, { request, result, requestPayload: { resultsNameSpace } }) => {
        const { data: results, meta: { totalPages } } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, [resultsNameSpace]: {results, totalPages, loading: false}  }
      },
      [this.SEARCH_FAILURE]: (state, { request, error, requestPayload: { resultsNameSpace } }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, [resultsNameSpace]: error }, [resultsNameSpace]: {...state[resultsNameSpace], loading: false} }
      }
    })
  }

  static saveSearchState(creator, reducer){
    creator((state) => {
      return {
        type: this.SAVE_SEARCH_STATE,
        payload: state
      }
    })

    reducer({
      [this.SAVE_SEARCH_STATE]: (state, searchState) => {
        return {...state, searchState}
      }
    })
  }

  static show(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.SHOW,
        promise: API.DealSheets.show(params)
      }
    })

    reducer({
      [this.SHOW_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: { }, dealSheet: {} }
      },
      [this.SHOW_SUCCESS]: (state, { request, result }) => {
        let { data: dealSheet, meta } = result
        const newRequests = state.requests.filter(r => r !== request)
        const validationErrors = (meta && meta.validationErrors) ? meta.validationErrors : {}

        return { ...state, requests: newRequests, dealSheet, validationErrors }
      },
      [this.SHOW_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, show: error } }
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      let {currency, issuerAccount, allowedCountries} = params
      let currencyId = params.currencyId || (currency ? currency.id : null)
      let issuerAccountId = issuerAccount ? issuerAccount.id : null

      let {logo, emblem, fundsUseInfographic, tokenDistributionInfographic, partners, galleryImages, downloads, teamMembers, ...dealSheetParams} = params
      let { certificationOfIncorporation, ...companyInfo } = dealSheetParams.companyInfo || {}
      dealSheetParams = {...dealSheetParams, companyInfo}

      if (allowedCountries !== undefined) {
        let allowedCountryIds = (allowedCountries && allowedCountries.length) ? allowedCountries.map(({id}) => id) : ['']
        dealSheetParams = {...dealSheetParams, allowedCountryIds}
      }

      return {
        type: this.UPDATE,
        promise: API.DealSheets.update({...dealSheetParams, currencyId, issuerAccountId})
      }
    })

    reducer({
      [this.UPDATE_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, createEvent: null} }
      },
      [this.UPDATE_SUCCESS]: (state, { request, result }) => {
        let { data: dealSheet } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, dealSheet, errors: {...state.errors, update: null} }
      },
      [this.UPDATE_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: {...state.errors, update: error} }
      }
    })
  }

  static updateFiles(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({id, logo, emblem, fundsUseInfographic, tokenDistributionInfographic, partners, galleryImages, downloads, teamMembers, ...dealSheet}) => {
      const { certificationOfIncorporation } = dealSheet.companyInfo || {}
      return {
        type: this.UPDATE_FILES,
        promise: API.DealSheets.updateFiles({
          id, logo, emblem, fundsUseInfographic, tokenDistributionInfographic,
          partners, galleryImages, downloads, teamMembers, companyInfo: { certificationOfIncorporation }
        })
      }
    })

    reducer({
      [this.UPDATE_FILES_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, updateFiles: null} }
      },
      [this.UPDATE_FILES_SUCCESS]: (state, { request, result }) => {
        let { data: dealSheet } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, dealSheet }
      },
      [this.UPDATE_FILES_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: {...state.errors, updateFiles: error} }
      }
    })
  }

  static create(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      let {currency, issuerAccount, allowedCountries} = params
      let currencyId = params.currencyId || (currency ? currency.id : null)
      let issuerAccountId = issuerAccount ? issuerAccount.id : null

      let allowedCountryIds = (allowedCountries && allowedCountries.length) ? allowedCountries.map(({id}) => id) : ['']
      let dealSheetParams = {...params, allowedCountryIds}

      return {
        type: this.CREATE,
        promise: API.DealSheets.create({...dealSheetParams, currencyId, issuerAccountId})
      }
    })

    reducer({
      [this.CREATE_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, create: null} }
      },
      [this.CREATE_SUCCESS]: (state, { request, result }) => {
        let { data: dealSheet, meta } = result
        const newRequests = state.requests.filter(r => r !== request)
        const validationErrors = (meta && meta.validationErrors) ? meta.validationErrors : {}

        return { ...state, requests: newRequests, dealSheet, validationErrors }
      },
      [this.CREATE_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: {...state.errors, create: error} }
      }
    })
  }

  // =====

  static createTermsAndConditionsPreview(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(params => {
      let {currency, issuerAccount} = params
      let currencyId = params.currencyId || (currency ? currency.id : null)
      let issuerAccountId = issuerAccount ? issuerAccount.id : null

      return {
        type: this.CREATE_TERMS_AND_CONDITIONS_PREVIEW,
        promise: API.DealSheets.termsAndConditionsPreview({...params, currencyId, issuerAccountId})
      }
    })

    reducer({
      [this.CREATE_TERMS_AND_CONDITIONS_PREVIEW_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, createTermsAndConditionsPreview: null} }
      },
      [this.CREATE_TERMS_AND_CONDITIONS_PREVIEW_SUCCESS]: (state, { request, result }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests }
      },
      [this.CREATE_TERMS_AND_CONDITIONS_PREVIEW_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: {...state.errors, createTermsAndConditionsPreview: error} }
      }
    })
  }

  static destroy(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => {
      const { id } = params

      return {
        type: this.DESTROY,
        promise: API.DealSheets.destroy({id})
      }
    })

    reducer({
      [this.DESTROY_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, destroy: null } }
      },
      [this.DESTROY_SUCCESS]: (state, { request, result }) => {
        const { meta: { id } } = result
        const requests = state.requests.filter(r => r !== request)

        const { dealSheets } = state
        const newDealSheets = dealSheets.filter(dealSheet => `${dealSheet.id}` !== `${id}`)
        return { ...state, dealSheets: newDealSheets, requests }
      },
      [this.DESTROY_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: { ...state.errors, destroy: error } }
      }
    })
  }

  static setFeaturedListings(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({ids, added}) => {
      return {
        type: this.SET_FEATURED_LISTINGS,
        payload: {ids, added},
        promise: API.DealSheets.setFeaturedListings({ids})
      }
    })

    reducer({
      [this.SET_FEATURED_LISTINGS_REQUEST]: (state, { requestPayload: {ids, added}, request }) => {
        let dealSheets = [...state.dealSheets, ...(added ? [added] : [])]
        return {
          ...state,
          dealSheets: ids.map(id => dealSheets.find(({id: dsId}) => dsId === id)),
          requests: [...state.requests, request], errors: {...state.errors, setFeaturedListings: null }
        }
      },
      [this.SET_FEATURED_LISTINGS_SUCCESS]: (state, { request }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests }
      },
      [this.SET_FEATURED_LISTINGS_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: { ...state.errors, setFeaturedListings: error } }
      }
    })
  }

  static loadIssuers(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(params => {
      return {
        type: this.LOAD_ISSUERS,
        promise: API.Issuers.index()
      }
    })

    reducer({
      [this.LOAD_ISSUERS_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, loadIssuers: null}}
      },
      [this.LOAD_ISSUERS_SUCCESS]: (state, { request, result }) => {
        const { data: issuers } = result
        const requests = state.requests.filter(r => r !== request)
        return { ...state, issuers, requests, errors: {...state.errors, loadIssuers: null}}
      },
      [this.LOAD_ISSUERS_FAILURE]: (state, {request, error}) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, issuers: [], requests, errors: {...state.errors, loadIssuers: error}}
      }
    })
  }

  static createEvent(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId, eventParams) => {
      const params = {id: dealSheetId, ...eventParams}
      return {
        type: this.CREATE_EVENT,
        promise: API.DealSheets.events(params)
      }
    })
    // TODO Update the dealsheet in dealSheet: and dealSheets:
    reducer({
      [this.CREATE_EVENT_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, createEvent: null} }
      },
      [this.CREATE_EVENT_SUCCESS]: (state, { request, result }) => {
        const { data: dealSheet, meta } = result
        const { dealSheets } = state
        const requests = state.requests.filter(r => r !== request)

        const newDealSheet = (state.dealSheet.id === dealSheet.id) ? dealSheet : state.dealSheet
        const newDealSheets = dealSheets.map(ds => (ds.id === dealSheet.id) ? dealSheet : ds )
        const validationErrors = (meta && meta.validationErrors) ? meta.validationErrors : {}

        return { ...state, dealSheets: newDealSheets, dealSheet: newDealSheet, requests, validationErrors }
      },
      [this.CREATE_EVENT_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: {...state.errors, createEvent: error} }
      }
    })
  }

  static loadPermittedViewers(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((page) => (dispatch, getState) => {
      const { dealSheets: { dealSheet } } = getState()

      return dispatch({
        type: this.LOAD_PERMITTED_VIEWERS,
        promise: API.DealSheets.permittedViewers({
          id: dealSheet.id,
          options: { page: { number: page, size: 15} }
        })
      })
    })

    reducer({
      [this.LOAD_PERMITTED_VIEWERS_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request],
          errors: {...state.errors, loadPermittedViewers: null} }
      },
      [this.LOAD_PERMITTED_VIEWERS_SUCCESS]: (state, { request, result }) => {
        const { data: values, meta: { totalPages } } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, permittedViewers: {values, totalPages}  }
      },
      [this.LOAD_PERMITTED_VIEWERS_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, loadPermittedViewers: error }}
      }
    })
  }


  static loadRestrictedViewers(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((page) => (dispatch, getState) => {
      const { dealSheets: { dealSheet } } = getState()

      return dispatch({
        type: this.LOAD_RESTRICTED_VIEWERS,
        promise: API.DealSheets.restrictedViewers({
          id: dealSheet.id,
          options: { page: { number: page, size: 15} }
        })
      })
    })

    reducer({
      [this.LOAD_RESTRICTED_VIEWERS_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request],
          errors: {...state.errors, loadRestrictedViewers: null} }
      },
      [this.LOAD_RESTRICTED_VIEWERS_SUCCESS]: (state, { request, result }) => {
        const { data: values, meta: { totalPages } } = result
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, restrictedViewers: {values, totalPages}  }
      },
      [this.LOAD_RESTRICTED_VIEWERS_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, loadRestrictedViewers: error }}
      }
    })
  }

  static permitViewers(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId, userIds = []) => {
      const params = { id: dealSheetId, userIds }
      return {
        type: this.PERMIT_VIEWERS,
        promise: API.DealSheets.permitViewers(params)
      }
    })

    reducer({
      [this.PERMIT_VIEWERS_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, permitViewers: null} }
      },
      [this.PERMIT_VIEWERS_SUCCESS]: (state, { request, result }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests }
      },
      [this.PERMIT_VIEWERS_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, permitViewers: error }}
      }
    })
  }

  static restrictViewers(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId, userIds = []) => {
      const params = { id: dealSheetId, userIds }
      return {
        type: this.RESTRICT_VIEWERS,
        promise: API.DealSheets.restrictViewers(params)
      }
    })

    reducer({
      [this.RESTRICT_VIEWERS_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, restrictViewers: null} }
      },
      [this.RESTRICT_VIEWERS_SUCCESS]: (state, { request, result }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests }
      },
      [this.RESTRICT_VIEWERS_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, restrictViewers: error }}
      }
    })
  }

  static removeViewer(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId, viewerId) => {
      const params = { id: dealSheetId, viewerId }
      return {
        type: this.REMOVE_VIEWER,
        promise: API.DealSheets.removeViewer(params)
      }
    })

    reducer({
      [this.REMOVE_VIEWER_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, removeViewer: null} }
      },
      [this.REMOVE_VIEWER_SUCCESS]: (state, { request, result }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests }
      },
      [this.REMOVE_VIEWER_FAILURE]: (state, { request, error }) => {
        const newRequests = state.requests.filter(r => r !== request)
        return { ...state, requests: newRequests, errors: { ...state.errors, removeViewer: error }}
      }
    })
  }

  static createWalletEvent(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((dealSheetId, eventParams) => {
      const params = {id: dealSheetId, ...eventParams}
      return {
        type: this.CREATE_WALLET_EVENT,
        promise: API.Wallets.events(params)
      }
    })
    reducer({
      [this.CREATE_WALLET_EVENT_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, 'wallet.createEvent': null} }
      },
      [this.CREATE_WALLET_EVENT_SUCCESS]: (state, { request, result }) => {
        const { data: wallet } = result
        const { dealSheets } = state
        const requests = state.requests.filter(r => r !== request)

        const newDealSheet = (state.dealSheet.payment_destination.id === wallet.id) ? {...state.dealSheet, wallet} : state.dealSheet
        const newDealSheets = dealSheets.map(ds => (ds.payment_destination.id === wallet.id && ds.payment_destination.type === 'wallets') ? {...ds, wallet} : ds )

        return { ...state, dealSheets: newDealSheets, dealSheet: newDealSheet, requests }
      },
      [this.CREATE_WALLET_EVENT_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: {...state.errors, 'wallet.createEvent': error} }
      }
    })
  }

  static loadWallet(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(() => (dispatch, getState) => {
      const {dealSheets: { dealSheet: { payment_destination: wallet } }} = getState()

      if (wallet) {
        const params = { id: wallet.id }
        return dispatch({
          type: this.LOAD_WALLET,
          promise: API.Wallets.show(params)
        })
      }
    })
    reducer({
      [this.LOAD_WALLET_REQUEST]: (state, { request }) => {
        return { ...state, requests: [...state.requests, request], errors: {...state.errors, 'wallet.load': null} }
      },
      [this.LOAD_WALLET_SUCCESS]: (state, { request, result }) => {
        const { data: wallet } = result
        const { dealSheets } = state
        const requests = state.requests.filter(r => r !== request)

        const newDealSheet = (state.dealSheet.payment_destination.id === wallet.id) ? {...state.dealSheet, wallet} : state.dealSheet
        const newDealSheets = dealSheets.map(ds => (ds.payment_destination.id === wallet.id && ds.payment_destination.type === 'wallets') ? {...ds, wallet} : ds )

        return { ...state, dealSheets: newDealSheets, dealSheet: newDealSheet, requests }
      },
      [this.LOAD_WALLET_FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(r => r !== request)
        return { ...state, requests, errors: {...state.errors, 'wallet.load': error} }
      }
    })
  }
}

export default new DealSheetActionSet()